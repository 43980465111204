import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import widthLimited from "@ui/components/Decorators/widthLimited";
import Skeleton from "react-loading-skeleton";
import {renderNodeOrComponent} from "@ui/components";

function SimpleProductsListHeader({
  styles,
  skeleton,
  title,
  IconComponent,
  TitleComponent = Title,
}) {
  return (
    <Container styles={styles}>
      <hr />
      {IconComponent && <div>{renderNodeOrComponent(IconComponent)}</div>}
      {!skeleton && <TitleComponent>{title}</TitleComponent>}
      {skeleton && <HeaderSkeleton />}
      <hr />
    </Container>
  );
}

const HeaderSkeleton = styled(Skeleton).attrs(() => ({}))`
  height: 50px;
  width: 300px;
`;

const Container = widthLimited(styled.div.attrs(() => ({
  className: "simple-card-list-header__container",
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;

  > hr {
    border-top: 1px solid;
    width: 100%;
    border-bottom: 0;
    border-color: ${({styles}) => styles.lineColor};
  }

  white-space: nowrap;
  width: 100%;
`);

const Title = styled.h1`
  margin: 0;
  padding: 0;
  white-space: nowrap;
`;
SimpleProductsListHeader.defaultProps = {
  styles: {
    color: "#000000",
    lineColor: "#CECECE",
    fontFamily: "sans-serif",
    fontSize: {
      sm: "24px",
      md: "32px",
      lg: "32px",
    },
    fontWeight: 600,
  },
};
SimpleProductsListHeader.propTypes = {
  styles: PropTypes.shape({
    color: PropTypes.string,
    lineColor: PropTypes.string,
    fontFamily: PropTypes.string,
    fontSize: PropTypes.shape({
      sm: PropTypes.string,
      md: PropTypes.string,
      lg: PropTypes.string,
    }),
    fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  skeleton: PropTypes.bool,
  title: PropTypes.string,
  IconComponent: PropTypes.elementType,
  TitleComponent: PropTypes.elementType,
};

export default SimpleProductsListHeader;
