import {makeThemeProps} from "@themes/default/props/utils";

export default (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getDealDetailProps(theme);
};

export const bundleListStyles = (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getBundleListProps(theme, site);
};

export const bundleCardStyles = (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getBundleCardProps(theme, site);
};
