import routes from "src/core/common/routes";
import useRouter from "src/core/common/hooks/useRouter";

function useProductPath() {
  const router = useRouter();
  const {productId} = router.query;

  function getProductPathDefinition(product) {
    const brandSlug = product.getBrandSlug();
    const path = {
      pathname: routes.getProductDetailPath({brand: brandSlug}),
      params: {
        productId: product.getSlug(),
        brand: brandSlug,
        category: product.getMainCategory().getSlug(),
      },
    };

    if (product.getConversionBreadcrumb()) {
      path.query = {
        cbreadcrumb: btoa(JSON.stringify(product.getConversionBreadcrumb())),
      };
    }

    return path;
  }
  function goToProduct(product) {
    const routeDef = getProductPathDefinition(product);
    router.push(routeDef);
  }

  return [productId, goToProduct, getProductPathDefinition];
}

export default useProductPath;
