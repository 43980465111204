import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import spacing from "@ui/utils/spacing";
import media from "@ui/utils/media";
import widthLimited from "@ui/components/Decorators/widthLimited";
import ProductListHeaderSkeleton from "./ProductListHeaderSkeleton";
import ReadMore from "../../ReadMore";
import renderNodeOrComponent from "../../../utils/RenderNodeOrComponent";
import Text from "@ui/components/Text";

export default function ProductsListHeader({
  styles,
  skeleton,
  title,
  description,
  ViewAllComponent,
  TitleComponent,
  FiltersComponent,
  SubcategoriesComponent,
  alignFiltersWithDescription = false,
  IconComponent,
}) {
  const _styles = merge({}, defaultStyles, styles);
  return (
    <Container styles={_styles.root}>
      {skeleton && !title && <ProductListHeaderSkeleton styles={_styles} />}
      {title && (
        <TitleSection
          viewAllPosition={_styles.viewAll.position}
          hasDescription={description}
        >
          <IconTitleContainer>
            {IconComponent}
            <TitleComponent variant={"h1"} title={title} styles={_styles.title}>
              {title}
            </TitleComponent>
          </IconTitleContainer>
          {ViewAllComponent && renderNodeOrComponent(ViewAllComponent)}
          {FiltersComponent &&
            !alignFiltersWithDescription &&
            renderNodeOrComponent(FiltersComponent)}
        </TitleSection>
      )}
      {title && (
        <DescriptionContainer>
          <DescriptionSection styles={_styles.descriptionContainer}>
            <ReadMore text={description} styles={_styles.description} />
          </DescriptionSection>
          {FiltersComponent &&
            alignFiltersWithDescription &&
            renderNodeOrComponent(FiltersComponent)}
        </DescriptionContainer>
      )}
      {SubcategoriesComponent && (
        <SubcategoriesContainer>
          {renderNodeOrComponent(SubcategoriesComponent)}
        </SubcategoriesContainer>
      )}
    </Container>
  );
}

const defaultStyles = {
  root: {},
  title: {
    fontFamily: "sans-serif",
    backgroundColor: "none",
    color: "black",
    fontWeight: "900",
    textTransform: "none",
    textAlign: "left",
    lineHeight: "1.5",
    fontSize: {
      lg: "32px",
      md: "32px",
      sm: "16px",
    },
  },
  descriptionContainer: {
    maxWidth: {
      lg: "75%",
      md: "75%",
      sm: "100vw",
    },
  },
  description: {},
  viewAll: {},
  filters: {},
};

const Container = widthLimited(styled.div.attrs(() => ({
  className: "products-list-header products-list-header__container",
}))`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: ${({styles}) => styles?.height};
`);

const TitleSection = styled.div.attrs(() => ({
  className: "products-list-header products-list-header__title-section",
}))`
  display: flex;
  justify-content: ${({viewAllPosition}) =>
    viewAllPosition === "left" ? "flex-start" : "space-between"};
  column-gap: 8px;
  align-items: center;
  margin-bottom: ${({hasDescription}) => (hasDescription ? spacing(2) : 0)};
`;

const DescriptionContainer = styled.div.attrs(() => ({
  className: "products-list-header products-list-header__description-container",
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DescriptionSection = styled.div.attrs(() => ({
  className: "products-list-header products-list-header__description-section",
}))`
  display: flex;
  max-width: ${({styles}) => styles.maxWidth.lg};
  ${media.down("md")} {
    max-width: ${({styles}) => styles.maxWidth.md};
  }
  ${media.down("sm")} {
    max-width: ${({styles}) => styles.maxWidth.sm};
  }
`;

const SubcategoriesContainer = styled.div.attrs(() => ({
  className: "products-list-header products-list-header__subcategories-section",
}))`
  margin-top: 18px;
`;

const IconTitleContainer = styled.div.attrs(() => ({
  className: "products-list-header products-list-header__icon-title-container",
}))`
  display: flex;
  flex-direction: row;
  gap: 5px;

  ${media.down("sm")} {
    align-items: center;
    gap: 16px;
  }
`;

ProductsListHeader.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.object,
    title: PropTypes.shape({
      fontFamily: PropTypes.string,
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    descriptionContainer: PropTypes.shape({
      maxWidth: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    description: PropTypes.object,
    viewAll: PropTypes.object,
    filters: PropTypes.object,
  }),
  title: PropTypes.string,
  description: PropTypes.string,
  ViewAllComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
  FiltersComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
  TitleComponent: PropTypes.elementType,
  SubcategoriesComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
  skeleton: PropTypes.bool,
  alignFiltersWithDescription: PropTypes.bool,
  IconComponent: PropTypes.elementType,
};

ProductsListHeader.defaultProps = {
  styles: defaultStyles,
  // eslint-disable-next-line react/prop-types
  TitleComponent: ({title, styles}) => (
    <Text variant={"h1"} text={title} styles={styles} />
  ),
};
