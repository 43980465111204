import {DealTypes} from "@deals/constants";
import routes from "src/core/common/routes";

export const groupItems = (items, cart) => {
  return items.reduce((acc, item) => {
    const bundlePromotion = cart?.getBundlePromotionsForItem(item);

    // Use the promotion ID or bundle ID to uniquely identify each bundle group
    const bundleId = bundlePromotion?.id || item.getBundleActive() || "noBundle";

    acc[bundleId] = acc[bundleId] || [];
    acc[bundleId].push(item);

    return acc;
  }, {});
};

export const getPromotionStatusMessage = bundleItems => {
  return bundleItems.some(item => item.getBundleActive() || item.getBogoActive())
    ? "Applied!"
    : "Available!";
};

export function getPromotionTypeAndSlug(items, cart) {
  return items
    .map(item => {
      const bogoPromotion = cart?.getBogoPromotionsForItem(item);
      if (bogoPromotion) {
        return {type: bogoPromotion.type, slug: bogoPromotion.slug};
      }
      const bundlePromotion = cart?.getBundlePromotionsForItem(item);
      if (bundlePromotion) {
        return {type: bundlePromotion.type, slug: bundlePromotion.slug};
      }
      return null;
    })
    .find(promotion => promotion !== null);
}

export function buildDealPath({type, slug}) {
  if (!type || !slug) return null;
  return {
    pathname: routes.dealDetail,
    params: {
      dealType: type !== DealTypes.REWARD ? DealTypes.PROMOTIONS : DealTypes.REWARDS,
      deal: slug?.toString(),
    },
  };
}
