import React from "react";
import DealDetail from "@ui/components/DealDetail";
import {
  SimpleProductsListHeader,
  ProductListHeader,
  MultiRowProductsList,
  isMobile,
  Text,
} from "@ui";
import styles, {
  bundleListStyles,
  bundleCardStyles,
} from "@themes/default/components/DealDetail/styles";
import useTheme from "@mock/hooks/useTheme";
import styled from "styled-components";
import {GiftIcon} from "@ui/components/IconsSVGs";
import media from "@ui/utils/media";
import ImageCard from "@ui/components/ImageCard";
import {DealTypes} from "@deals/constants";

export default function DefaultDealDetail(props) {
  const theme = useTheme();
  const {
    deal,
    loading,
    site,
    onClickCopyButton,
    addItem,
    isBogoActive,
    bundleProducts,
    currentBundleQuantity,
  } = props;

  const dealStyles = styles(theme, site);
  const bundleStyles = bundleListStyles(theme, site);
  const cardStyles = bundleCardStyles(theme, site);

  const listTitle =
    currentBundleQuantity < deal?.bundleQuantity
      ? `Select bundle products, ${currentBundleQuantity} of ${deal?.bundleQuantity} selected`
      : "Deal unlocked";

  const missingProducts = deal?.bundleQuantity - currentBundleQuantity;

  const updatedBundleProducts = [
    ...bundleProducts,
    ...Array.from({length: missingProducts}, () => ({skeleton: true})),
  ];

  const MobileTitle = () => (
    <Text
      variant="h3"
      textAlign="left"
      text={listTitle}
      styles={bundleStyles?.header?.title}
    />
  );

  const Title = () => <Text text={listTitle} styles={bundleStyles?.header?.title} />;

  const Header = isMobile() ? (
    <ProductListHeader
      title={listTitle}
      TitleComponent={MobileTitle}
      IconComponent={
        <GiftIcon width={24} height={24} color={bundleStyles?.header?.title?.color} />
      }
    />
  ) : (
    <SimpleProductsListHeader
      IconComponent={
        <GiftIcon width={24} height={24} color={bundleStyles?.header?.title?.color} />
      }
      TitleComponent={Title}
    />
  );

  return (
    <>
      <Container>
        <DealDetail
          skeleton={loading}
          image={deal?.image}
          title={deal?.title}
          description={deal?.description}
          type={deal?.type}
          disclaimer={deal?.disclaimer}
          promoCodes={deal?.promoCodes}
          category={deal?.category}
          styles={dealStyles}
          addItem={addItem}
          isBogoActive={isBogoActive}
          onClickCopyButton={onClickCopyButton}
        />
      </Container>
      {deal?.type === DealTypes.BUNDLE && (
        <BundleContainer>
          <MultiRowProductsList
            styles={bundleStyles}
            products={updatedBundleProducts}
            ProductCardComponent={<ImageCard styles={cardStyles} />}
            HeaderComponent={Header}
          />
        </BundleContainer>
      )}
    </>
  );
}

const Container = styled.div`
  margin-bottom: 32px;
`;

const BundleContainer = styled.div`
  background-color: #eeeeee;
  padding: 32px;
  margin: 32px;

  ${media.down("sm")} {
    margin: 16px;
  }
`;
