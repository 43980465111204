import autoBind from "auto-bind";
import AccessoriesPlaceholderImage from "../assets/item-placeholders/accessories.jpg";
import makeFlowerType from "./flowerType";
import ProductCategory from "./productCategory";
import ProductTag from "../../common/models/productTag";

class Product {
  static Substances = {
    THC: "thc",
    CBD: "cbd",
  };

  constructor(productCardObject) {
    this.productCardObject = productCardObject;
    autoBind(this);
  }

  withConversionBreadcrumb(origin, zone) {
    const newProductCardObject = {
      ...this.productCardObject,
      attributes: {
        ...this.productCardObject.attributes,
        conversion_breadcrumb: {
          conversion_origin: origin,
          ui_zone: zone,
        },
      },
    };

    return new Product(newProductCardObject);
  }

  getConversionBreadcrumb() {
    return this.productCardObject?.attributes?.conversion_breadcrumb; // {ui_zone: "", conversion_origin: ""}
  }

  getId() {
    return this.productCardObject?.attributes?.id;
  }

  getExternalId() {
    return this.productCardObject?.attributes?.external_id;
  }

  getSku() {
    return this.productCardObject?.attributes?.sku;
  }

  getDescription() {
    return this.productCardObject?.attributes?.description;
  }

  getType() {
    return this.productCardObject?.attributes?.type;
  }

  getMainImage() {
    return this.productCardObject?.attributes?.main_image || AccessoriesPlaceholderImage;
  }

  getFlowerType() {
    return makeFlowerType(this.productCardObject?.attributes?.flower_type || "");
  }

  getName() {
    return this.productCardObject?.attributes?.name;
  }

  getSlug() {
    return this.productCardObject?.attributes?.slug;
  }

  getUnitPrice(quantity) {
    const unitPrice = this.productCardObject?.attributes?.unit_price;
    if (quantity) {
      const unitPriceObj = this.findUnitPriceForQuantity(quantity);
      return unitPriceObj ? unitPriceObj.unitPrice : unitPrice;
    } else {
      return unitPrice;
    }
  }

  getDiscountPrice(quantity) {
    let localQuantity = quantity;
    // This is when there is not a defined quantity for the product yet,
    // meaning there was not a defined quantity in the context where this functions was called
    // E.g., product card when the user didn't have added it to the cart yet
    if (localQuantity === 0) {
      localQuantity = 1;
    }

    const unitDiscountedPrice = this.productCardObject?.attributes?.discount_price;
    const price = this.getUnitPrice(localQuantity);
    let discountedPrice = this.hasUnitPrices() ? null : unitDiscountedPrice;

    if (localQuantity) {
      const unitPriceObj = this.findUnitPriceForQuantity(localQuantity);
      if (unitPriceObj && unitPriceObj.discountedPrice) {
        discountedPrice = unitPriceObj.discountedPrice;
      } else if (localQuantity === 1) {
        discountedPrice = unitDiscountedPrice;
      }
    }

    if (!discountedPrice) return null;

    if (price && discountedPrice.amount === price.amount) return null;

    return discountedPrice;
  }

  getFinalPriceByWeightKey(weightKey) {
    if (this.hasWeightPrices()) {
      return this.getWeightPrices().find(wp => wp.weightKey === weightKey);
    } else {
      return this.getDiscountPrice() || this.getUnitPrice();
    }
  }

  getFinalPrice(weight, quantity) {
    if (weight) {
      return weight.discountedPrice || weight.price;
    } else if (quantity && this.hasUnitPrices()) {
      return this.getDiscountPrice(quantity) || this.getUnitPrice(quantity);
    } else {
      return this.getDiscountPrice() || this.getUnitPrice();
    }
  }

  getPriceRange(quantity) {
    if (this.hasWeightPrices()) {
      const weightPrices = this.getWeightPrices();
      return weightPrices.reduce((acc, wp) => {
        if (acc === null) return {min: wp.price, max: wp.price};

        return {
          min: wp.price.amount < acc.min.amount ? wp.price : acc.min,
          max: wp.price.amount > acc.max.amount ? wp.price : acc.max,
        };
      }, null);
    } else {
      const price = this.getFinalPrice(null, quantity);
      return {min: price, max: price};
    }
  }

  isTaxIncluded() {
    return false;
  }

  getBrandName() {
    return this.productCardObject?.relationships?.brand?.data?.attributes?.name || "";
  }

  getBrandSlug() {
    return this.productCardObject?.relationships?.brand?.data?.attributes?.slug || "";
  }

  getCategories() {
    const category = this.getMainCategory();
    if (category) return [category.getName()];

    return [];
  }

  getMainCategory() {
    return new ProductCategory(this.productCardObject?.relationships?.category?.data);
  }

  getTags() {
    return (this.productCardObject?.relationships?.tags?.data || []).map(tagData => {
      return new ProductTag(tagData);
    });
  }

  isInStock() {
    return this.productCardObject?.attributes?.in_stock || true;
  }

  isValid() {
    return this.isInStock() && Boolean(this.getUnitPrice() || this.hasWeightPrices());
  }

  getDetailedComposition() {
    const result = Object.keys(Product.Substances)
      .map(key => {
        const substance = this.getSubstance(Product.Substances[key]);
        const substanceRange = this.getSubstanceRange(Product.Substances[key]);

        if (substanceRange)
          return `${key}: ${substanceRange.min} - ${substanceRange.max}${substanceRange.units}`;
        if (substance) return `${key}: ${substance.amount}${substance.units}`;

        return null;
      })
      .filter(entry => entry);

    return result.length > 0 ? result : null;
  }

  getSubstanceRange(substance) {
    const substance_max =
      this.productCardObject?.attributes?.[`max_${substance}`] || null;
    const substance_min =
      this.productCardObject?.attributes?.[`min_${substance}`] || null;

    if (!substance_max || !substance_min) return null;

    return {
      max: substance_max.amount,
      min: substance_min.amount,
      units: substance_max.units,
    };
  }

  getSubstance(substance) {
    return this.productCardObject?.attributes?.[substance] || null;
  }

  getSize() {
    const sizeObj = this.productCardObject?.attributes?.size || null;
    return sizeObj && new ProductSize(sizeObj);
  }

  getSizes() {
    if (this.hasWeightPrices()) {
      return this.getWeightPrices().map(wp => new ProductSize(wp.sizeObj));
    } else if (this.getSize()) {
      return [this.getSize()];
    } else {
      return null;
    }
  }

  hasWeightPrices() {
    const options = this.getWeightPrices();
    return Array.isArray(options) && options.length > 0;
  }

  getWeightPrices() {
    const weightPrices = this.productCardObject?.attributes?.weight_prices;
    if (!weightPrices) return null;

    return weightPrices.map(obj => new SizeOption(obj));
  }

  findUnitPriceForQuantity = quantity => {
    if (!this.hasUnitPrices()) return null;
    const unitPrices = this.getUnitPrices();
    let unitPrice = null;
    for (let i = 0; i < unitPrices.length; i++) {
      const currentQuantity = unitPrices[i].quantity;
      if (currentQuantity > quantity) break;
      if (currentQuantity === quantity) {
        unitPrice = unitPrices[i];
        break;
      }
      if (
        !unitPrice ||
        (unitPrice.quantity < currentQuantity && currentQuantity < quantity)
      ) {
        unitPrice = unitPrices[i];
      }
    }
    return unitPrice || null;
  };

  getUnitPrices() {
    const unitPrices = this.productCardObject?.attributes?.unit_prices;
    if (!unitPrices) return null;

    return unitPrices.map(obj => new SizeOption(obj));
  }

  hasUnitPrices() {
    const unitPrices = this.getUnitPrices();
    return unitPrices && unitPrices.length > 1;
  }

  getFirstWeightPrice() {
    if (!this.hasWeightPrices()) return null;

    const weightPrices = this.getWeightPrices();
    return weightPrices[0];
  }

  validForSale() {
    return this.productCardObject?.attributes?.valid_for_sale || true;
  }

  getSavings(quantity) {
    const zero = {amount: 0, currency: "usd"};
    const expectedProductUnitPrice = this.getUnitPrice(1);
    const productUnitPrice = this.getFinalPrice(null, quantity);

    const expectedTotalPrice = {
      amount: expectedProductUnitPrice.amount * quantity,
      currency: expectedProductUnitPrice.currency,
    };
    const finalPrice = {
      amount: productUnitPrice.amount * quantity,
      currency: productUnitPrice.currency,
    };

    if (finalPrice.amount < expectedTotalPrice.amount) {
      return {
        amount: expectedTotalPrice.amount - finalPrice.amount,
        currency: finalPrice.currency,
      };
    } else {
      return zero;
    }
  }

  isSaving(quantity) {
    return (
      this.hasUnitPrices() &&
      !this.hasWeightPrices() &&
      this.getSavings(quantity).amount > 0
    );
  }

  isOnSale() {
    return this.productCardObject?.attributes?.on_sale;
  }
}

export class SizeOption {
  constructor(sizeObj) {
    this.sizeObj = sizeObj;
  }

  get quantity() {
    return this.sizeObj?.quantity;
  }

  get label() {
    return this.sizeObj?.display_name;
  }

  get price() {
    return this.sizeObj?.price;
  }

  get discountedPrice() {
    return this.sizeObj?.discount_price;
  }

  get finalPrice() {
    return this.discountedPrice || this.price;
  }

  get weightKey() {
    return this.sizeObj?.weight_key;
  }

  get unitPrice() {
    if (!this.price || !this.quantity) {
      return null;
    }
    return {
      amount: Math.round(this.price.amount / this.quantity),
      currency: this.price.currency,
    };
  }
}

class ProductSize {
  constructor(productSizeObject) {
    this.productSizeObject = productSizeObject;
    autoBind(this);
  }

  toString() {
    return (
      this.productSizeObject.display_text || this.productSizeObject.display_name || ""
    );
  }
}

export default Product;
