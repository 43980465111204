import merge from "lodash/merge";
import get from "lodash/get";

export default Clazz => {
  Object.assign(Clazz.prototype, themeProps);

  return Clazz;
};

const themeProps = {
  getCheckoutHeaderProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.checkoutHeader)};
    const primaryButtonStyles = {
      v2: merge({}, _theme.v2, _theme.v2.overrides.primaryButton),
    };

    return {
      root: {
        padding: {
          lg: `${theme.v2.spacing(2)} ${theme.v2.spacing(4)}`,
          md: `${theme.v2.spacing(1)} ${theme.v2.spacing(2)}`,
          sm: `${theme.v2.spacing(1)} ${theme.v2.spacing(2)}`,
        },
      },
      backButton: {
        fontFamily: theme.v2.typography.title6.family,
        fontWeight: theme.v2.typography.title6.weight,
        fontSize: theme.v2.typography.title6.size,
        color: theme.v2.color.typography.title6,
        border: `${theme.v2.decoration.border.width.xs} solid ${theme.v2.color.typography.title6}`,
        borderRadius: theme.v2.decoration.border.radius.xxs,
        textTransform: primaryButtonStyles.v2.typography.bodyText1.textCase,
      },
    };
  },

  getCheckoutOrderSummaryProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.checkoutOrderSummary)};

    return {
      expandableArea: {
        container: {
          background: theme.v2.color.base.grey["100"],
          width: {
            lg: "100%",
            md: "100%",
            sm: "100%",
          },
          padding: {
            sm: "0",
            md: theme.v2.static.spacing.size["3xl"],
            lg: theme.v2.static.spacing.size["3xl"],
          },
          contentMaxWidth: {
            sm: "768px",
            md: "420px",
            lg: "420px",
          },
        },
        expandableContent: {
          padding: {
            sm: theme.v2.static.spacing.size.m,
            md: theme.v2.static.spacing.size.m,
            lg: theme.v2.static.spacing.size.m,
          },
        },
      },
      divider: {
        height: theme.v2.decoration.border.width.xs,
        background: theme.v2.color.base.grey["300"],
        margin: {
          sm: `${theme.v2.static.spacing.size.l} 0px`,
          md: `${theme.v2.static.spacing.size.l} 0px`,
          lg: `${theme.v2.static.spacing.size.l} 0px`,
        },
      },
    };
  },

  getCheckoutMobileHeaderProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.checkoutMobileHeader)};

    return {
      container: {
        background: theme.v2.color.base.grey["100"],
        borderBottom: `${theme.v2.decoration.border.width.xs} solid ${theme.v2.color.base.grey["300"]}`,
        padding: theme.v2.static.spacing.size.m,
        gap: theme.v2.static.spacing.size.xs,
      },
      title: {
        fontFamily: theme.v2.typography.title6.family,
        fontSize: theme.v2.typography.title6.size.sm,
        fontWeight: theme.v2.typography.title6.weight,
        fontStyle: "normal",
        color: theme.v2.color.typography.bodyText2,
      },
      totalPrice: {
        fontFamily: theme.v2.typography.title4.family,
        fontSize: theme.v2.typography.title4.size.sm,
        fontStyle: "normal",
        color: theme.v2.color.typography.bodyText2,
        letterSpacing: "-0.2px",
        lineHeight: theme.v2.typography.title4.lineHeight,
      },
    };
  },

  getCheckoutProductSectionProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.checkoutProductSection)};

    return {
      productSectionContainer: {
        gap: {
          sm: theme.v2.static.spacing.size.m,
          md: theme.v2.static.spacing.size.m,
          lg: theme.v2.static.spacing.size.m,
        },
      },
      emptyCart: {
        fontFamily: theme.v2.typography.title4.family,
        fontSize: {
          sm: theme.v2.typography.title4.size.sm,
          md: theme.v2.typography.title4.size.md,
          lg: theme.v2.typography.title4.size.lg,
        },
        fontWeight: theme.v2.typography.fontWeight,
        color: theme.v2.color.base.grey["600"],
        padding: {
          sm: `${theme.v2.static.spacing.size.l} 0px ${theme.v2.static.spacing.size.m} 0px`,
          md: `${theme.v2.static.spacing.size.l} 0px ${theme.v2.static.spacing.size.m} 0px`,
          lg: `${theme.v2.static.spacing.size.l} 0px ${theme.v2.static.spacing.size.m} 0px`,
        },
      },
      productItem: {
        container: {
          gap: theme.v2.static.spacing.size.m,
        },
        productImageContainer: {
          border: `${theme.v2.decoration.border.width.xs} solid ${theme.v2.color.base.grey["300"]}`,
          borderRadius: theme.v2.decoration.border.radius.xxs,
        },
        productImage: {
          borderRadius: theme.v2.decoration.border.radius.xxs,
        },
        itemQuantity: {
          background: theme.v2.color.base.grey["100"],
          borderRadius: theme.v2.decoration.border.radius.xxs,
          color: theme.v2.color.base.grey["600"],
          quantityColor: theme.v2.color.base.grey["900"],
          fontFamily: theme.v2.typography.caption1.family,
          fontWeight: theme.v2.typography.caption1.weight,
          fontSize: theme.v2.typography.caption1.size,
          lineHeight: theme.v2.typography.caption1.lineHeight,
        },
        brandName: {
          color: theme.v2.color.typography.caption1,
          fontFamily: theme.v2.typography.caption1.family,
          fontWeight: theme.v2.typography.caption1.weight,
          fontSize: theme.v2.typography.caption1.size,
          lineHeight: theme.v2.typography.caption1.lineHeight,
        },
        errorMessage: {
          color: theme.v2.color.error,
          fontFamily: theme.v2.typography.caption1.family,
          fontWeight: theme.v2.typography.caption1.weight,
          fontSize: theme.v2.typography.caption1.size,
          lineHeight: theme.v2.typography.caption1.lineHeight,
        },
        productName: {
          color: theme.v2.color.typography.bodyText2,
          fontFamily: theme.v2.typography.bodyText2.family,
          fontWeight: theme.v2.typography.bodyText2.weight,
          fontSize: theme.v2.typography.bodyText2.size,
          lineHeight: theme.v2.typography.bodyText2.lineHeight,
        },
        productSize: {
          color: theme.v2.color.typography.caption1,
          fontFamily: theme.v2.typography.caption1.family,
          fontWeight: theme.v2.typography.caption1.weight,
          fontSize: theme.v2.typography.caption1.size,
          lineHeight: theme.v2.typography.caption1.lineHeight,
          gap: theme.v2.static.spacing.size.xs,
        },
        removeButton: {
          marginLeft: theme.v2.static.spacing.size.xs,
          color: theme.v2.color.typography.bodyText1,
          fontFamily: theme.v2.typography.caption1.family,
          fontWeight: theme.v2.typography.caption1.weight,
          fontSize: theme.v2.typography.caption1,
          lineHeight: theme.v2.typography.caption1.lineHeight,
          hover: {
            textDecoration: theme.v2.text.decorations.underline,
          },
        },
        productPrice: {
          color: theme.v2.color.typography.bodyText2,
          fontFamily: theme.v2.typography.bodyText2.family,
          fontWeight: theme.v2.typography.bodyText2.weight,
          fontSize: theme.v2.typography.bodyText2.size,
          lineHeight: theme.v2.typography.bodyText2.lineHeight,
          crossed: {
            color: theme.v2.color.typography.caption1,
            fontFamily: theme.v2.typography.caption1.family,
            fontWeight: theme.v2.typography.caption1.weight,
            fontSize: theme.v2.typography.caption1.size,
            lineHeight: theme.v2.typography.caption1.lineHeight,
          },
        },
        productDiscountedPrice: {
          color: theme.v2.color.typography.bodyText2,
          fontFamily: theme.v2.typography.bodyText2.family,
          fontWeight: theme.v2.typography.bodyText2.weight,
          fontSize: theme.v2.typography.bodyText2.size,
          lineHeight: theme.v2.typography.bodyText2.lineHeight,
        },
      },
    };
  },

  getCheckouTotalSectionProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.checkoutTotalSection)};
    return {
      container: {
        gap: "12px",
      },
      label: {
        color: theme.v2.color.base.grey["800"],
        fontFamily: theme.v2.typography.bodyText2.family,
        fontSize: theme.v2.typography.bodyText2.size,
        fontWeight: theme.v2.typography.bodyText2.weight,
        fontStyle: "normal",
        lineHeight: theme.v2.typography.bodyText2.lineHeight,
      },
      sublabel: {
        fontFamily: theme.v2.typography.bodyText2.family,
        fontSize: theme.v2.typography.bodyText2.size,
        fontWeight: theme.v2.typography.bodyText2.weight,
        color: theme.v2.color.base.grey["600"],
      },
      groupLabel: {
        color: theme.v2.color.base.grey["800"],
        fontFamily: theme.v2.typography.bodyText2.family,
        fontSize: theme.v2.typography.bodyText2.size,
        fontWeight: theme.v2.typography.bodyText2.weight,
        fontStyle: "normal",
        lineHeight: theme.v2.typography.bodyText2.lineHeight,
      },
      discountCode: {
        fontFamily: theme.v2.typography.titles.family,
        fontSize: theme.v2.typography.bodyText2.size,
        fontWeight: theme.v2.typography.bodyText2.weight,
        fontStyle: "normal",
        color: theme.v2.color.typography.caption1,
        lineHeight: theme.v2.typography.bodyText2.lineHeight,
        letterSpacing: "-0.2px",
        textTransform: theme.v2.text.case.uppercase,
      },
      price: {
        color: theme.v2.color.base.grey["800"],
        fontFamily: theme.v2.typography.bodyText2.family,
        fontSize: theme.v2.typography.bodyText2.size,
        fontWeight: theme.v2.typography.bodyText2.weight,
        fontStyle: "normal",
        lineHeight: theme.v2.typography.bodyText2.lineHeight,
      },
      totalLabel: {
        color: theme.v2.color.typography.bodyText2,
        fontFamily: theme.v2.typography.title6.family,
        fontSize: theme.v2.typography.title6.size,
        fontWeight: theme.v2.typography.title6.weight,
        fontStyle: "normal",
        lineHeight: theme.v2.typography.title6.lineHeight,
      },
      totalCurrency: {
        fontFamily: theme.v2.typography.caption1.family,
        fontSize: theme.v2.typography.caption1.size,
        fontWeight: theme.v2.typography.caption1.weight,
        fontStyle: "normal",
        color: theme.v2.color.typography.caption1,
        lineHeight: theme.v2.typography.caption1.lineHeight,
      },
      totalValue: {
        fontFamily: theme.v2.typography.title3.family,
        fontSize: theme.v2.typography.title3.size,
        fontWeight: theme.v2.typography.titles.weight.heavy,
        fontStyle: "normal",
        color: theme.v2.color.typography.bodyText2,
        lineHeight: theme.v2.typography.title3.lineHeight,
        letterSpacing: theme.v2.typography.caption1.letterSpacing,
      },
    };
  },

  getCheckoutPromoCodeSectionProps(_theme) {
    const theme = {
      v2: merge({}, _theme.v2, _theme.v2.overrides.checkoutPromoCodeSection),
    };

    return {
      inputRow: {
        gap: {
          lg: theme.v2.static.spacing.size.m,
          md: theme.v2.static.spacing.size.m,
          sm: theme.v2.static.spacing.size.m,
        },
      },
      promotionRow: {
        gap: {
          lg: theme.v2.static.spacing.size.m,
          md: theme.v2.static.spacing.size.m,
          sm: theme.v2.static.spacing.size.m,
        },
        padding: {
          lg: `${theme.v2.static.spacing.size.m} 0px 0px 0px`,
          md: `${theme.v2.static.spacing.size.m} 0px 0px 0px`,
          sm: `${theme.v2.static.spacing.size.m} 0px 0px 0px`,
        },
      },
      input: {
        fontFamily: theme.v2.typography.bodyText2.family,
        fontSize: theme.v2.typography.bodyText2.size,
        background: theme.v2.color.background,
        border: `${theme.v2.decoration.border.width.xs} solid ${theme.v2.color.base.grey["300"]}`,
        borderRadius: theme.v2.decoration.border.radius.xxs,
        placeholderColor: theme.v2.color.typography.caption1,
        padding: {
          lg: theme.v2.static.spacing.size.s,
          md: theme.v2.static.spacing.size.s,
          sm: theme.v2.static.spacing.size.s,
        },
        hover: {
          borderColor: theme.v2.color.base.grey["400"],
        },
        error: {
          border: `${theme.v2.decoration.border.width.xs} solid ${theme.v2.color.error}`,
          placeholderColor: theme.v2.color.error,
        },
        disabled: {
          background: theme.v2.color.base.grey["100"],
          border: `${theme.v2.decoration.border.width.xs} solid ${theme.v2.color.base.grey["300"]}`,
        },
      },
      button: {
        root: {
          fontSize: theme.v2.typography.bodyText2.size,
        },
      },
      promoTag: {
        container: {
          background: theme.v2.color.base.grey["200"],
          border: "none",
          borderRadius: theme.v2.decoration.border.radius.xxs,
          padding: {
            lg: `${theme.v2.static.spacing.size.xs} ${theme.v2.static.spacing.size.s}`,
            md: `${theme.v2.static.spacing.size.xs} ${theme.v2.static.spacing.size.s}`,
            sm: `${theme.v2.static.spacing.size.xs} ${theme.v2.static.spacing.size.s}`,
          },
          error: {
            background: "#F8D7DA", //TODO: MISSING TOKEN
            border: `${theme.v2.decoration.border.width.xs} solid ${theme.v2.color.error}`,
          },
          disabledOpacity: 0.4,
        },
        label: {
          fontFamily: theme.v2.typography.bodyText2.family,
          fontSize: theme.v2.typography.bodyText2.size,
          fontWeight: theme.v2.typography.bodyText2.weight,
          color: theme.v2.color.typography.bodyText2,
          fontStyle: "normal",
        },
        removeIcon: {
          color: theme.v2.color.base.grey["700"],
          size: "18px",
          errorColor: theme.v2.color.error,
        },
        errorIcon: {
          color: theme.v2.color.error,
          width: "18px",
          height: "18px",
        },
        leftIcon: {
          color: theme.v2.color.base.grey["700"],
          width: "18px",
          height: "19px",
        },
      },
    };
  },

  getCheckoutRewardSectionProps(_theme) {
    const theme = {
      v2: merge({}, _theme.v2, _theme.v2.overrides.checkoutPromoCodeSection),
    };

    return {
      promotionRow: {
        gap: {
          lg: theme.v2.static.spacing.size.m,
          md: theme.v2.static.spacing.size.m,
          sm: theme.v2.static.spacing.size.m,
        },
        padding: {
          lg: `${theme.v2.static.spacing.size.m} 0px 0px 0px`,
          md: `${theme.v2.static.spacing.size.m} 0px 0px 0px`,
          sm: `${theme.v2.static.spacing.size.m} 0px 0px 0px`,
        },
      },
      title: {
        color: theme.v2.color.base.grey["800"],
        fontFamily: theme.v2.typography.bodyText2.family,
      },
      infoNotification: {
        root: {
          border: `${theme.v2.decoration.border.width.xs} solid #D9D9D9`, //TODO: missing token
          borderRadius: `${theme.v2.decoration.border.radius.default}`,
          padding: `${theme.v2.static.spacing.size.m}`,
          infoBorder: {
            border: `${theme.v2.decoration.border.width.xs} solid ${theme.v2.color.base.grey[500]}`,
            borderWidth: `${theme.v2.decoration.border.width.xs} ${theme.v2.decoration.border.width.xs} ${theme.v2.decoration.border.width.xs} ${theme.v2.decoration.border.width.m}`,
          },
          errorBorder: {
            border: `${theme.v2.decoration.border.width.xs} solid ${theme.v2.color.error}`,
            borderWidth: `${theme.v2.decoration.border.width.xs} ${theme.v2.decoration.border.width.xs} ${theme.v2.decoration.border.width.xs} ${theme.v2.decoration.border.width.m}`,
          },
        },
        icon: {
          sucess: {
            color: "#3EC84C", //success color
          },
          error: {
            color: theme.v2.color.error,
          },
          info: {
            color: theme.v2.color.onSurface,
          },
        },
        title: {
          fontFamily: theme.v2.typography.title1.family,
          fontSize: theme.v2.typography.bodyText2.size,
          fontWeight: theme.v2.typography.title1.weight,
          color: theme.v2.color.typography.bodyText2,
        },
        description: {
          fontFamily: theme.v2.typography.bodyText2.family,
          fontSize: theme.v2.typography.bodyText2.size,
          fontWeight: theme.v2.typography.bodyText2.weight,
          color: theme.v2.color.base.grey["600"],
        },
        action: {
          fontFamily: theme.v2.typography.bodyText2.family,
          fontSize: theme.v2.typography.bodyText2.size,
          fontWeight: theme.v2.typography.bodyText2.weight,
          color: theme.v2.color.typography.bodyText2,
          background: theme.v2.color.background,
          border: `${theme.v2.decoration.border.width.xs} solid #D9D9D9`,
          borderRadius: `${theme.v2.decoration.border.radius.xxs}`,
          padding: `${theme.v2.static.spacing.size.xs}`,
        },
      },
      dropdownSelectInput: {
        placeholder: {
          fontFamily: theme.v2.typography.bodyText2.family,
          fontSize: theme.v2.typography.bodyText2.size,
          fontWeight: theme.v2.typography.bodyText2.weight,
          textTransform: "none",
          color: theme.v2.color.typography.caption1,
        },
        control: {
          height: "46px",
          border: `${theme.v2.decoration.border.width.xs} solid ${theme.v2.color.base.grey["300"]}`,
          borderRadius: theme.v2.decoration.border.radius.xxs,
          hover: {
            border: `${theme.v2.decoration.border.width.xs} solid ${theme.v2.color.base.grey["400"]}`,
          },
          focus: {
            border: `${theme.v2.decoration.border.width.xs} solid ${theme.v2.color.base.grey["300"]}`,
          },
        },
        indicatorSeparator: {
          backgroundColor: theme.v2.color.base.grey["300"],
          width: theme.v2.decoration.border.width.xs,
        },
        input: {
          fontFamily: theme.v2.typography.bodyText2.family,
          fontSize: theme.v2.typography.bodyText2.size,
          fontWeight: theme.v2.typography.bodyText2.weight,
          textTransform: "none",
          color: theme.v2.color.typography.bodyText2,
        },
        menu: {
          fontFamily: theme.v2.typography.bodyText2.family,
          fontSize: theme.v2.typography.bodyText2.size,
          fontWeight: theme.v2.typography.bodyText2.weight,
          textTransform: "none",
          color: theme.v2.color.typography.bodyText2,
          backgroundColor: theme.v2.color.background,
          border: `${theme.v2.decoration.border.width.xs} solid ${theme.v2.color.base.grey["300"]}`,
          borderRadius: theme.v2.decoration.border.radius.xxs,
          boxShadow: "none",
        },
        option: {
          isFocused: {
            color: theme.v2.color.typography.bodyText1,
            backgroundColor: theme.v2.color.base.grey["100"],
          },
          isSelected: {
            color: theme.v2.color.typography.bodyText1,
            backgroundColor: theme.v2.color.base.grey["300"],
          },
        },
      },
      promoTag: {
        container: {
          background: theme.v2.color.base.grey["200"],
          border: "none",
          borderRadius: theme.v2.decoration.border.radius.xxs,
          padding: {
            lg: `${theme.v2.static.spacing.size.xs} ${theme.v2.static.spacing.size.s}`,
            md: `${theme.v2.static.spacing.size.xs} ${theme.v2.static.spacing.size.s}`,
            sm: `${theme.v2.static.spacing.size.xs} ${theme.v2.static.spacing.size.s}`,
          },
          error: {
            background: "#F8D7DA", //TODO: MISSING TOKEN
            border: `${theme.v2.decoration.border.width.xs} solid ${theme.v2.color.error}`,
          },
          disabledOpacity: 0.4,
        },
        label: {
          fontFamily: theme.v2.typography.bodyText2.family,
          fontSize: theme.v2.typography.bodyText2.size,
          fontWeight: theme.v2.typography.bodyText2.weight,
          color: theme.v2.color.typography.bodyText2,
          fontStyle: "normal",
        },
        removeIcon: {
          color: theme.v2.color.base.grey["700"],
          size: "18px",
          errorColor: theme.v2.color.error,
        },
        errorIcon: {
          color: theme.v2.color.error,
          width: "18px",
          height: "18px",
        },
        leftIcon: {
          color: theme.v2.color.base.grey["700"],
          width: "18px",
          height: "19px",
        },
      },
    };
  },

  getCheckoutStepIndicatorProps(_theme) {
    const theme = {
      v2: merge({}, _theme.v2, _theme.v2.overrides?.checkout?.stepIndicator),
    };

    return {
      title: {
        fontFamily: theme.v2.typography.title1.family,
        fontSize: theme.v2.typography.title1.size.lg,
        fontWeight: theme.v2.typography.title1.weight,
        color: theme.v2.color.typography.bodyText2,
      },
      indicator: {
        fontFamily: theme.v2.typography.caption1.family,
        fontSize: theme.v2.typography.caption1.size,
        fontWeight: theme.v2.typography.caption1.weight,
        color: theme.v2.color.typography.caption1,
        selectedColor: theme.v2.color.base.grey["900"],
      },
    };
  },

  getCheckoutContactInfoProps(_theme) {
    const theme = {
      v2: merge({}, _theme.v2, _theme.v2.overrides?.checkout?.contactInfo),
    };

    return {
      title: {
        fontFamily: theme.v2.typography.title4.family,
        fontSize: theme.v2.typography.title4.size.lg,
        fontWeight: theme.v2.typography.title4.weight,
        color: theme.v2.color.typography.bodyText2,
      },
      content: {
        label: {
          fontFamily: theme.v2.typography.bodyText2.family,
          fontSize: theme.v2.typography.bodyText2.size,
          fontWeight: theme.v2.typography.bodyText2.weight,
          color: theme.v2.color.base.grey["600"],
        },
        value: {
          fontFamily: theme.v2.typography.bodyText2.family,
          fontSize: theme.v2.typography.bodyText2.size,
          fontWeight: theme.v2.typography.bodyText2.weight,
          color: theme.v2.color.typography.bodyText2,
          errorColor: theme.v2.color.error,
        },
        actionButton: {
          fontFamily: theme.v2.typography.caption1.family,
          fontSize: theme.v2.typography.caption1.size,
          fontWeight: theme.v2.typography.caption1.weight,
          color: theme.v2.color.base.grey["900"],
          lineHeight: 2,
        },
      },
    };
  },

  getCheckoutDeliveryTypeProps(_theme) {
    const theme = {
      v2: merge({}, _theme.v2, _theme.v2.overrides?.checkout?.deliveryType),
    };

    const borderRadius =
      theme.v2.decoration.border.radius.xxs || theme.v2.decoration.border.radius.default;

    return {
      title: {
        fontFamily: theme.v2.typography.title4.family,
        fontSize: theme.v2.typography.title4.size.lg,
        fontWeight: theme.v2.typography.title4.weight,
        color: theme.v2.color.typography.bodyText2,
      },
      content: {
        optionRow: {
          checkbox: {
            width: "18px",
            height: "18px",
            selectedWidth: "8px",
            selectedHeight: "8px",
            borderRadius: "34px",
            border: "1px solid #D9D9D9",
            selectedBorder: `6px solid ${theme.v2.color.primary}`,
          },
          label: {
            fontFamily: theme.v2.typography.bodyText2.family,
            fontSize: theme.v2.typography.bodyText2.size,
            fontWeight: theme.v2.typography.bodyText2.weight,
            color: theme.v2.color.base.grey["600"],
            selectedColor: theme.v2.color.typography.bodyText2,
            noOptionText: {
              fontFamily: theme.v2.typography.bodyText2.family,
              fontSize: theme.v2.typography.bodyText2.size,
              fontWeight: theme.v2.typography.bodyText2.weight,
              color: theme.v2.color.typography.bodyText2,
            },
          },
          value: {
            fontFamily: theme.v2.typography.bodyText2.family,
            fontSize: theme.v2.typography.bodyText2.size,
            fontWeight: theme.v2.typography.bodyText2.weight,
            color: theme.v2.color.typography.bodyText2,
            selectedColor: theme.v2.color.typography.bodyText2,
          },
          helperText: {
            text: {
              fontFamily: theme.v2.typography.caption1.family,
              fontSize: theme.v2.typography.caption1.size,
              fontWeight: theme.v2.typography.caption1.weight,
              color: theme.v2.color.typography.caption1,
            },
            noOptionText: {
              fontFamily: theme.v2.typography.bodyText2.family,
              fontSize: theme.v2.typography.bodyText2.size,
              fontWeight: theme.v2.typography.bodyText2.weight,
              color: theme.v2.color.typography.bodyText2,
            },
          },
          actionButton: {
            fontFamily: theme.v2.typography.caption1.family,
            fontSize: theme.v2.typography.caption1.size,
            fontWeight: theme.v2.typography.caption1.weight,
            color: theme.v2.color.base.grey["900"],
          },
        },
        error: {
          color: theme.v2.color.error,
        },
        container: {
          border: "1px solid #D9D9D9",
          borderRadius: borderRadius,
          error: {
            border: `1px solid ${theme.v2.color.error}`,
          },
        },
      },
      modal: {
        title: {
          fontFamily: theme.v2.typography.title3.family,
          fontSize: {
            lg: theme.v2.typography.title4.size.lg,
            md: theme.v2.typography.title4.size.lg,
            sm: theme.v2.typography.title5.size.lg,
          },
          fontWeight: theme.v2.typography.title3.weight,
          color: theme.v2.color.typography.title3,
        },
        subtitle: {
          fontFamily: theme.v2.typography.title4.family,
          fontSize: theme.v2.typography.title4.size,
          fontWeight: theme.v2.typography.title4.weight,
          color: theme.v2.color.typography.title4,
        },
        helperText: {
          fontFamily: theme.v2.typography.bodyText1.family,
          fontSize: theme.v2.typography.bodyText1.size,
          fontWeight: theme.v2.typography.bodyText1.weight,
          color: theme.v2.color.typography.bodyText1,
        },
        announcement: {
          fontFamily: theme.v2.typography.bodyText1.family,
          fontSize: theme.v2.typography.bodyText1.size,
          fontWeight: theme.v2.typography.bodyText1.weight,
          color: theme.v2.color.typography.bodyText1,
        },
        emptyPlaceholder: {
          subtitle: {
            fontFamily: theme.v2.typography.bodyText2.family,
            fontSize: theme.v2.typography.bodyText2.size,
            fontWeight: theme.v2.typography.bodyText2.weight,
            color: theme.v2.color.typography.bodyText2,
            lineHeight: theme.v2.typography.bodyText2.lineHeight,
          },
          text: {
            fontFamily: theme.v2.typography.caption1.family,
            fontSize: theme.v2.typography.caption1.size,
            fontWeight: theme.v2.typography.caption1.weight,
            color: theme.v2.color.typography.caption1,
            lineHeight: theme.v2.typography.caption1.lineHeight,
          },
        },
        slot: {
          root: {
            fontFamily: theme.v2.typography.bodyText2.family,
            fontSize: theme.v2.typography.bodyText2.size,
            fontWeight: theme.v2.typography.bodyText2.weight,
            color: theme.v2.color.typography.bodyText2,
            textTransform: "none",
            width: "100%",
          },
        },
        confirm: {
          root: {
            fontFamily: theme.v2.typography.title6.family,
            fontSize: theme.v2.typography.title6.size.lg,
            fontWeight: theme.v2.typography.title6.weight,
            borderRadius: theme.v2.decoration.border.radius.xxs,
          },
          primary: {
            backgroundColor: theme.v2.color.primary,
            color: theme.v2.color.onPrimary,
            border: `1px solid ${theme.v2.color.primary}`,
          },
          secondary: {
            backgroundColor: theme.v2.color.onPrimary,
            color: theme.v2.color.primary,
            border: `1px solid ${theme.v2.color.primary}`,
          },
        },
        cancel: {
          root: {
            fontFamily: theme.v2.typography.title6.family,
            fontSize: theme.v2.typography.title6.size.lg,
            fontWeight: theme.v2.typography.title6.weight,
            borderRadius: theme.v2.decoration.border.radius.xxs,
          },
          primary: {
            backgroundColor: theme.v2.color.primary,
            color: theme.v2.color.onPrimary,
            border: `1px solid ${theme.v2.color.primary}`,
          },
          secondary: {
            backgroundColor: theme.v2.color.onPrimary,
            color: theme.v2.color.primary,
            border: `1px solid ${theme.v2.color.primary}`,
          },
        },
        submit: {
          root: {
            fontFamily: theme.v2.typography.title6.family,
            fontSize: theme.v2.typography.title6.size.lg,
            fontWeight: theme.v2.typography.title6.weight,
            borderRadius: theme.v2.decoration.border.radius.xxs,
          },
          primary: {
            backgroundColor: theme.v2.color.primary,
            color: theme.v2.color.onPrimary,
            border: `1px solid ${theme.v2.color.primary}`,
          },
        },
      },
    };
  },

  getCheckoutOrderNotesProps(_theme) {
    const theme = {
      v2: merge({}, _theme.v2, _theme.v2.overrides?.checkout?.orderNotes),
    };

    return {
      title: {
        fontFamily: theme.v2.typography.title4.family,
        fontSize: theme.v2.typography.title4.size.lg,
        fontWeight: theme.v2.typography.title4.weight,
        color: theme.v2.color.typography.bodyText2,
      },
      field: {
        fontFamily: theme.v2.typography.bodyText2.family,
        fontSize: theme.v2.typography.bodyText2.size,
        fontWeight: theme.v2.typography.bodyText2.weight,
        color: theme.v2.color.typography.bodyText2,
        placeholderColor: theme.v2.color.base.grey["600"],
      },
    };
  },

  getCheckoutAdditionalOrderInfoProps(_theme) {
    const theme = {
      v2: merge({}, _theme.v2, _theme.v2.overrides?.checkout?.additionalOrderInfo),
    };

    return {
      additionalInfo: {
        text: {
          fontFamily: get(theme, "v2.typography.caption2.family"),
          fontSize: get(theme, "v2.typography.caption2.size"),
          color: get(theme, "v2.color.base.grey.700"),
          lineHeight: {
            lg: "17px",
            md: "17px",
            sm: "17px",
          },
        },
      },
      legalWarning: {
        text: {
          fontFamily: get(theme, "v2.typography.caption2.family"),
          fontSize: get(theme, "v2.typography.caption2.size"),
          color: get(theme, "v2.color.base.grey.700"),
          lineHeight: {
            lg: "17px",
            md: "17px",
            sm: "17px",
          },
        },
      },
    };
  },

  getCheckoutActionsProps(_theme) {
    const theme = {
      v2: merge({}, _theme.v2, _theme.v2.overrides?.checkout?.actions),
    };

    const primaryButtonStyles = {
      v2: merge({}, _theme.v2, _theme.v2.overrides.primaryButton),
    };

    return {
      button: {
        root: {
          textTransform: primaryButtonStyles.v2.typography.bodyText1.textCase,
          fontFamily: theme.v2.typography.title6.family,
          fontSize: theme.v2.typography.title6.size.lg,
          fontWeight: theme.v2.typography.title6.weight,
          borderRadius: theme.v2.decoration.border.radius.xxs,
          width: "100%",
        },
        primary: {
          backgroundColor: theme.v2.color.primary,
          color: theme.v2.color.onPrimary,
          border: `1px solid ${theme.v2.color.primary}`,
        },
        secondary: {
          backgroundColor: theme.v2.color.onPrimary,
          color: theme.v2.color.primary,
          border: `1px solid ${theme.v2.color.primary}`,
        },
      },
    };
  },

  getPaymentSectionProps(_theme) {
    const theme = {
      v2: merge({}, _theme.v2, _theme.v2.overrides?.checkout?.additionalOrderInfo),
    };

    return {
      title: {
        fontFamily: theme.v2.typography.title4.family,
        fontSize: theme.v2.typography.title4.size.lg,
        fontWeight: theme.v2.typography.title4.weight,
        color: theme.v2.color.typography.bodyText2,
      },
      subtitle: {
        fontFamily: theme.v2.typography.bodyText2.family,
        fontSize: theme.v2.typography.bodyText2.size,
        fontWeight: theme.v2.typography.bodyText2.weight,
        color: theme.v2.color.typography.bodyText2,
      },
      content: {
        optionRow: {
          checkbox: {
            width: "18px",
            height: "18px",
            selectedWidth: "8px",
            selectedHeight: "8px",
            borderRadius: "34px",
            border: "1px solid #D9D9D9",
            selectedBorder: `6px solid ${theme.v2.color.primary}`,
          },
          label: {
            fontFamily: theme.v2.typography.bodyText2.family,
            fontSize: theme.v2.typography.bodyText2.size,
            fontWeight: theme.v2.typography.bodyText2.weight,
            color: theme.v2.color.base.grey["600"],
            selectedColor: theme.v2.color.typography.bodyText2,
          },
        },
      },
    };
  },

  getTipSectionProps(_theme) {
    const theme = _theme.v2;
    const borderRadius =
      theme.decoration.border.radius.xxs || theme.decoration.border.radius.default;

    const tipsTheme = merge({}, _theme.v2, _theme.v2.overrides?.checkout?.tips);

    return {
      root: {
        border: `${theme.decoration.border.width.xs} solid #D9D9D9`,
        borderRadius: borderRadius,
      },
      title: {
        fontFamily: theme.typography.title4.family,
        fontSize: theme.typography.title4.size.lg,
        fontWeight: theme.typography.title4.weight,
        color: theme.color.typography.bodyText2,
      },
      header: {
        border: `${theme.decoration.border.width.xs} solid #D9D9D9`,
        padding: theme.static.spacing.size.m,
        fontFamily: theme.typography.bodyText2.family,
        fontSize: theme.typography.bodyText2.size,
        fontWeight: theme.typography.bodyText2.weight,
        color: theme.color.typography.bodyText2,
      },
      body: {
        backgroundColor: theme.color.base.grey["100"],
        padding: theme.static.spacing.size.m,
      },
      tips: {
        border: `${tipsTheme.decoration.border.width.xs} solid #D9D9D9`,
        selectedBorder: `2px solid ${tipsTheme.color.primary}`,
        borderRadius: borderRadius,
        backgroundColor: tipsTheme.color.background,
        padding: tipsTheme.static.spacing.size.s,
        percentage: {
          fontFamily: tipsTheme.typography.bodyText2.family,
          fontSize: tipsTheme.typography.bodyText2.size,
          fontWeight: tipsTheme.typography.bodyText2.weight,
          color: tipsTheme.color.typography.bodyText2,
        },
        amount: {
          fontFamily: tipsTheme.typography.caption1.family,
          fontSize: tipsTheme.typography.caption1.size,
          fontWeight: tipsTheme.typography.caption1.weight,
          color: tipsTheme.color.typography.caption1,
        },
        noTip: {
          fontFamily: tipsTheme.typography.bodyText2.family,
          fontSize: tipsTheme.typography.bodyText2.size,
          fontWeight: tipsTheme.typography.bodyText2.weight,
          color: tipsTheme.color.onSurface,
        },
        customTip: {
          border: `${tipsTheme.decoration.border.width.xs} solid #D9D9D9`,
          selectedBorder: `2px solid ${tipsTheme.color.primary}`,
          borderRadius: borderRadius,
          backgroundColor: tipsTheme.color.background,
          padding: tipsTheme.static.spacing.size.s,
          fontFamily: tipsTheme.typography.bodyText1.family,
          fontSize: tipsTheme.typography.bodyText1.size,
          fontWeight: tipsTheme.typography.bodyText1.weight,
          color: tipsTheme.color.typography.bodyText1,
        },
      },
      applyButton: {
        backgroundColor: "#000",
        borderRadius: borderRadius,
        padding: "12px 20px",
        fontFamily: theme.typography.bodyText2.family,
        fontSize: theme.typography.bodyText2.size,
        fontWeight: theme.typography.bodyText2.weight,
        color: theme.color.onPrimary,
      },
      footNote: {
        fontFamily: theme.typography.bodyText2.family,
        fontSize: theme.typography.bodyText2.size,
        fontWeight: theme.typography.bodyText2.weight,
        color: theme.color.typography.bodyText2,
      },
    };
  },

  getLedgerGreenFormProps(theme) {
    return {
      ...this.getStrongholdFormProps(theme),
      error: {
        fontFamily: theme.v2.typography.bodyText2.family,
        fontSize: {
          sm: "12px",
          md: "12px",
          lg: "12px",
        },
        fontWeight: "400",
        fontStyle: "normal",
        color: "#EB5757",
        lineHeight: "150%",
        letterSpacing: "-0.2px",
      },
    };
  },

  getLedgerGreenCreditCardFormProps(_theme) {
    const theme = _theme.v2;
    return {
      input: {
        height: "44px",
        borderRadius: theme.decoration.border.radius.default,
        fontFamily: theme.typography.bodyText2.family,
        fontSize: theme.typography.bodyText2.size,
      },
      date: {
        height: "44px",
        borderRadius: theme.decoration.border.radius.default,
        fontFamily: theme.typography.bodyText2.family,
        fontSize: theme.typography.bodyText2.size?.lg,
        padding: {
          lg: "12px",
          md: "12px",
          sm: "12px",
        },
      },
      select: {
        placeholder: {
          fontFamily: theme.typography.bodyText2.family,
          fontWeight: theme.typography.bodyText2.weight,
          fontSize: theme.typography.bodyText2.size,
        },
        input: {
          fontFamily: theme.typography.bodyText2.family,
          fontWeight: theme.typography.bodyText2.weight,
          fontSize: theme.typography.bodyText2.size,
        },
        menu: {
          fontFamily: theme.typography.bodyText2.family,
          fontWeight: theme.typography.bodyText2.weight,
          fontSize: theme.typography.bodyText2.size,
        },
        control: {
          height: "46px",
          borderRadius: theme.decoration.border.radius.default,
        },
      },
    };
  },

  getLedgerGreenAgreementFormProps(_theme) {
    const theme = _theme.v2;

    return {
      text: {
        fontSize: theme.typography.bodyText2.size,
        fontFamily: theme.typography.bodyText2.family,
      },
      signature: {
        borderRadius: theme.decoration.border.radius.default,
      },
    };
  },

  getLedgerGreenMultiStepFormProps(_theme) {
    const theme = _theme.v2;

    return {
      root: {
        borderRadius: theme.decoration.border.radius.default,
      },
      stepIndicator: {
        stepNumber: {
          backgroundColor: theme.color.primary,
          fontSize: theme.typography.title6.size,
          color: theme.color.onPrimary,
          fontFamily: theme.typography.title6.family,
        },
        stepLabel: {
          fontFamily: theme.typography.title6.family,
          fontSize: theme.typography.title6.size,
        },
      },
      continueButton: {
        root: {
          fontSize: theme.typography.title6.size?.lg,
          fontFamily: theme.typography.title6.family,
          borderRadius: theme.decoration.border.radius.default,
          fontWeight: theme.typography.title6.weight,
          border: "none",
        },
        primary: {
          backgroundColor: theme.color.primary,
          color: theme.color.onPrimary,
        },
      },
    };
  },

  getCheckoutFullScreenLoadingProps(_theme) {
    const theme = _theme.v2;

    return {
      loading: {
        spinner: {
          root: {
            color: theme.color.primary,
            thickness: "20px",
          },
        },
        feedbackText: {
          upperText: {
            fontFamily: theme.typography.title6.family,
            fontWeight: theme.typography.title6.weight,
            fontSize: theme.typography.title6.size?.lg,
          },
          lowerText: {
            fontFamily: theme.typography.bodyText2.family,
            fontWeight: theme.typography.bodyText2.weight,
            fontSize: theme.typography.bodyText2.size?.lg,
          },
        },
      },
      slowConnectionIndicator: {
        root: {
          border: "1px solid #D9D9D9",
          borderRadius: "4px",
          padding: "16px",
          errorBorder: {
            border: "1px solid #EB5757",
            borderWidth: "1px 1px 1px 4px",
          },
          infoBorder: {
            border: "1px solid #E0E0E0",
            borderWidth: "1px 1px 1px 4px",
          },
        },
        icon: {},
        title: {
          fontFamily: theme.typography.title6.family,
          fontSize: theme.typography.title6.size,
          fontWeight: theme.typography.title6.weight,
          color: theme.color.typography.title6,
        },
        description: {
          fontFamily: theme.typography.bodyText2.family,
          fontSize: theme.typography.bodyText2.size,
          fontWeight: theme.typography.bodyText2.weight,
          color: theme.color.typography.bodyText2,
        },
      },
    };
  },

  getStrongholdPromotionBannerProps(_theme) {
    const theme = _theme.v2;

    return {
      title: {
        fontFamily: theme.typography.title6.family,
        fontSize: theme.typography.title6.size,
        fontWeight: theme.typography.title6.weight,
      },
      description: {
        fontFamily: theme.typography.bodyText2.family,
        fontSize: theme.typography.bodyText2.size,
        fontWeight: theme.typography.bodyText2.weight,
      },
    };
  },

  getStrongholdFormProps(_theme) {
    const theme = _theme.v2;
    const borderRadius =
      theme.decoration.border.radius.xxs || theme.decoration.border.radius.default;

    return {
      container: {
        padding: theme.static.spacing.size.m,
      },
      optionPicker: {
        container: {
          borderRadius: `${borderRadius} ${borderRadius} 0px 0px`,
          border: `${theme.decoration.border.width.xs} solid #D9D9D9`,
          alignItems: "start",
        },
        optionRow: {
          row: {
            alignItems: "start",
            gap: {
              lg: "0px",
              md: "0px",
              sm: "0px",
            },
            padding: {
              lg: theme.static.spacing.size.m,
              md: theme.static.spacing.size.m,
              sm: theme.static.spacing.size.m,
            },
            borderRadius: `${borderRadius} ${borderRadius} 0px 0px`,
          },
          labelContainer: {
            padding: `0px ${theme.static.spacing.size.m} 0px ${theme.static.spacing.size.m};`,
          },
          title: {
            fontFamily: theme.typography.bodyText2.family,
            fontSize: theme.typography.bodyText2.size,
            fontWeight: theme.typography.bodyText2.weight,
            color: theme.color.onSurface,
          },
          subtitle: {
            fontFamily: theme.typography.caption1.family,
            fontSize: theme.typography.caption1.size,
            fontWeight: theme.typography.caption1.weight,
            color: theme.color.typography.caption1,
          },
        },
      },
      linkAccount: {
        backgroundColor: theme.color.surface,
        textAlign: "start",
        textDecoration: "underline",
        border: `${theme.decoration.border.width.xs} solid #D9D9D9`,
        borderRadius: `0px 0px ${borderRadius} ${borderRadius}`,
        padding: theme.static.spacing.size.m,
      },
      emptyPlaceholder: {
        backgroundColor: theme.color.surface,
        border: `${theme.decoration.border.width.xs} solid #D9D9D9`,
        borderRadius: `${borderRadius} ${borderRadius} 0px 0px`,
        padding: theme.static.spacing.size.m,
      },
      emptyPlaceholderTitle: {
        fontFamily: theme.typography.bodyText1.family,
        fontSize: theme.typography.bodyText1.size,
        fontWeight: theme.typography.bodyText1.weight,
        color: theme.color.onSurface,
        marginTop: theme.static.spacing.size.xs,
      },
      emptyPlaceholderSubtitle: {
        fontFamily: theme.typography.caption1.family,
        fontSize: theme.typography.caption1.size,
        fontWeight: theme.typography.caption1.weight,
        color: theme.color.typography.caption1,
      },
    };
  },

  getAdyenFormProps(theme) {
    return this.getMerrcoFormProps(theme);
  },

  getAdyenCreditCardFormProps(theme) {
    return this.getLedgerGreenCreditCardFormProps(theme);
  },

  getMerrcoFormProps(_theme) {
    const theme = _theme.v2;
    const borderRadius =
      theme.decoration.border.radius.xxs || theme.decoration.border.radius.default;

    return {
      container: {
        padding: theme.static.spacing.size.m,
      },
      creditCardPanel: {
        optionPicker: {
          container: {
            borderRadius: borderRadius,
            border: `${theme.decoration.border.width.xs} solid #D9D9D9`,
            alignItems: "start",
          },
          optionRow: {
            row: {
              gap: {
                lg: theme.static.spacing.size.xs,
                md: theme.static.spacing.size.xs,
                sm: theme.static.spacing.size.xs,
              },
              padding: {
                lg: theme.static.spacing.size.m,
                md: theme.static.spacing.size.m,
                sm: theme.static.spacing.size.m,
              },
              borderRadius: `${borderRadius} ${borderRadius} 0px 0px`,
              lastChildRadius: `0px 0px ${borderRadius} ${borderRadius}`,
            },
            labelContainer: {
              padding: `0px`,
            },
            label: {
              fontFamily: theme.typography.bodyText2.family,
              fontSize: theme.typography.bodyText2.size,
              fontWeight: theme.typography.bodyText2.weight,
              color: theme.color.onSurface,
              textTransform: "none",
            },
            title: {
              fontFamily: theme.typography.bodyText2.family,
              fontSize: theme.typography.bodyText2.size,
              fontWeight: theme.typography.bodyText2.weight,
              color: theme.color.onSurface,
              expired: {
                color: theme.color.base.grey[500],
              },
            },
            subtitle: {
              fontFamily: theme.typography.caption1.family,
              fontSize: theme.typography.caption1.size,
              fontWeight: theme.typography.caption1.weight,
              color: theme.color.typography.caption1,
              expired: {
                color: theme.color.error,
              },
            },
          },
        },
        newCreditCardForm: {
          gap: theme.static.spacing.size.m,
          error: {
            fontFamily: theme.typography.caption1.family,
            fontSize: theme.typography.caption1.size,
            fontWeight: theme.typography.caption1.weight,
            color: theme.color.error,
            lineHeight: "150%",
            letterSpacing: "-0.2px",
          },
          input: {
            fontFamily: theme.typography.bodyText2.family,
            fontSize: theme.typography.bodyText2.size,
            fontWeight: theme.typography.bodyText2.weight,
            background: theme.color.background,
            border: `${theme.decoration.border.width.xs} solid #D9D9D9`,
            borderRadius: borderRadius,
            placeholderColor: "#737373",
            padding: {
              lg: theme.static.spacing.size.s,
              md: theme.static.spacing.size.s,
              sm: theme.static.spacing.size.s,
            },
            hover: {
              borderColor: "hsl(0, 0%, 70%)",
            },
            error: {
              border: `${theme.decoration.border.width.xs} solid ${theme.color.error}`,
              placeholderColor: theme.color.error,
            },
            disabled: {
              background: "hsl(0, 0%, 95%)",
              border: `${theme.decoration.border.width.xs} solid #D9D9D9`,
            },
          },
        },
      },
    };
  },

  getMonerisFormProps(_theme) {
    const theme = _theme.v2;
    const borderRadius =
      theme.decoration.border.radius.xxs || theme.decoration.border.radius.default;

    return {
      container: {
        padding: theme.static.spacing.size.m,
      },
      iframe: {
        height: "108px",
      },
      bodyCss: `
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: normal;
        align-items: normal;
        align-content: normal;
        gap: ${theme.static.spacing.size.m};
        height: fit-content;
      `,
      textBoxCss: `
        width: 100%;
        height: fit-content;
        flex-grow: 1;
        padding: ${theme.static.spacing.size.s};
        border-radius: ${borderRadius};
        border: 1px solid #D9D9D9;
        font-family: none;
        font-size: ${theme.typography.bodyText2.size.lg};
        font-weight: ${theme.typography.caption1.weight};
        line-height: 140%;
        color: ${theme.color.onSurface};
      `,
      cardTextBoxCss: `
        width: 100%;
      `,
      expTextBoxCss: `
        width: 60%;
      `,
      cvcTextBoxCss: `
        width: 30%;
      `,
      cardholderInput: {
        fontFamily: "none",
        fontSize: theme.typography.bodyText2.size,
        fontWeight: theme.typography.caption1.weight,
        borderRadius: borderRadius,
        border: "1px solid #D9D9D9",
        padding: {
          lg: theme.static.spacing.size.s,
          md: theme.static.spacing.size.s,
          sm: theme.static.spacing.size.s,
        },
        hover: {
          borderColor: "#D9D9D9",
        },
        disabled: {
          background: "white",
        },
        focus: {
          border: "2px solid rgb(0, 95, 204)",
        },
      },
    };
  },

  getOrderDetailProps(_theme) {
    const theme = {
      v2: merge({}, _theme.v2, _theme.v2.overrides.orderDetail),
    };

    return {
      shop: {
        fontFamily: theme.v2.typography.title2.family,
        fontSize: theme.v2.typography.title2.size,
        fontWeight: theme.v2.typography.title2.weight,
        color: theme.v2.color.typography.bodyText2,
      },
      header: {
        icon: {
          color: theme.v2.color.primary,
        },
        topText: {
          fontFamily: theme.v2.typography.bodyText2.family,
          fontSize: theme.v2.typography.bodyText2.size,
          fontWeight: theme.v2.typography.bodyText2.weight,
          color: theme.v2.color.base.grey["700"],
        },
        customerName: {
          fontFamily: theme.v2.typography.title4.family,
          fontSize: theme.v2.typography.title4.size,
          fontWeight: theme.v2.typography.title4.weight,
          color: theme.v2.color.typography.title4,
        },
      },
      mobileAppPromotion: {
        root: {
          border: "1px solid #D9D9D9",
          borderRadius: "5px",
          padding: "14px 14px 18px",
        },
        title: {
          fontFamily: theme.v2.typography.title4.family,
          fontSize: theme.v2.typography.title4.size,
          fontWeight: theme.v2.typography.title4.weight,
          color: theme.v2.color.typography.title4,
        },
        description: {
          fontFamily: theme.v2.typography.caption2.family,
          fontSize: theme.v2.typography.caption2.size,
          fontWeight: theme.v2.typography.caption2.weight,
          color: theme.v2.color.typography.caption2,
        },
      },
      order: {
        title: {
          fontFamily: theme.v2.typography.title4.family,
          fontSize: theme.v2.typography.title4.size,
          fontWeight: theme.v2.typography.title4.weight,
          color: theme.v2.color.typography.bodyText2,
        },
        info: {
          fontFamily: theme.v2.typography.bodyText2.family,
          fontSize: theme.v2.typography.bodyText2.size,
          fontWeight: theme.v2.typography.bodyText2.weight,
          color: theme.v2.color.base.grey["700"],
        },
        states: {
          selectedColor: theme.v2.color.primary,
        },
        label: {
          fontFamily: theme.v2.typography.title6.family,
          fontSize: theme.v2.typography.title6.size,
          fontWeight: theme.v2.typography.title6.weight,
          color: theme.v2.color.typography.bodyText2,
        },
        footer: {
          backgroundColor: theme.v2.color.base.grey["100"],
          iconColor: theme.v2.color.primary,
          title: {
            fontFamily: theme.v2.typography.title6.family,
            fontSize: theme.v2.typography.title6.size,
            fontWeight: theme.v2.typography.title6.weight,
            color: theme.v2.color.typography.bodyText2,
          },
        },
      },
      customer: {
        title: {
          fontFamily: theme.v2.typography.title4.family,
          fontSize: theme.v2.typography.title4.size,
          fontWeight: theme.v2.typography.title4.weight,
          color: theme.v2.color.typography.bodyText2,
        },
        info: {
          title: {
            fontFamily: theme.v2.typography.bodyText2.family,
            fontSize: theme.v2.typography.bodyText2.size,
            fontWeight: theme.v2.typography.bodyText2.weight,
            color: theme.v2.color.typography.bodyText2,
          },
          text: {
            fontFamily: theme.v2.typography.bodyText2.family,
            fontSize: theme.v2.typography.bodyText2.size,
            fontWeight: theme.v2.typography.bodyText2.weight,
            color: theme.v2.color.base.grey["700"],
          },
        },
        saveInfo: {
          root: {
            fontFamily: theme.v2.typography.bodyText2.family,
            fontSize: theme.v2.typography.bodyText2.size,
            fontWeight: theme.v2.typography.bodyText2.weight,
            color: theme.v2.color.base.grey["700"],
          },
        },
      },
      paymentInfo: {
        title: {
          fontFamily: theme.v2.typography.title4.family,
          fontSize: theme.v2.typography.title4.size,
          fontWeight: theme.v2.typography.title4.weight,
          color: theme.v2.color.typography.bodyText2,
        },
        info: {
          title: {
            fontFamily: theme.v2.typography.bodyText2.family,
            fontSize: theme.v2.typography.bodyText2.size,
            fontWeight: theme.v2.typography.bodyText2.weight,
            color: theme.v2.color.typography.bodyText2,
          },
          text: {
            fontFamily: theme.v2.typography.bodyText2.family,
            fontSize: theme.v2.typography.bodyText2.size,
            fontWeight: theme.v2.typography.bodyText2.weight,
            color: theme.v2.color.base.grey["700"],
          },
        },
        paymentTitle: {
          fontFamily: theme.v2.typography.bodyText2.family,
          fontSize: theme.v2.typography.bodyText2.size,
          fontWeight: theme.v2.typography.bodyText2.weight,
          color: theme.v2.color.typography.bodyText2,
        },
        paymentSubtitle: {
          fontFamily: theme.v2.typography.caption1.family,
          fontSize: theme.v2.typography.caption1.size,
          fontWeight: theme.v2.typography.caption1.weight,
          color: theme.v2.color.typography.caption1,
        },
        paymentDate: {
          fontFamily: theme.v2.typography.caption1.family,
          fontSize: theme.v2.typography.caption1.size,
          fontWeight: theme.v2.typography.caption1.weight,
          color: theme.v2.color.typography.caption1,
        },
      },
      infoNotification: {
        root: {
          border: `${theme.v2.decoration.border.width.xs} solid #D9D9D9`, //TODO: missing token
          borderRadius: `${theme.v2.decoration.border.radius.default}`,
          padding: `${theme.v2.static.spacing.size.m}`,
          infoBorder: {
            border: `${theme.v2.decoration.border.width.xs} solid ${theme.v2.color.base.grey[500]}`,
            borderWidth: `${theme.v2.decoration.border.width.xs} ${theme.v2.decoration.border.width.xs} ${theme.v2.decoration.border.width.xs} ${theme.v2.decoration.border.width.m}`,
          },
          errorBorder: {
            border: `${theme.v2.decoration.border.width.xs} solid ${theme.v2.color.error}`,
            borderWidth: `${theme.v2.decoration.border.width.xs} ${theme.v2.decoration.border.width.xs} ${theme.v2.decoration.border.width.xs} ${theme.v2.decoration.border.width.m}`,
          },
        },
        icon: {
          sucess: {
            color: "#3EC84C", //success color
          },
          error: {
            color: theme.v2.color.error,
          },
          info: {
            color: theme.v2.color.onSurface,
          },
        },
        title: {
          fontFamily: theme.v2.typography.title1.family,
          fontSize: theme.v2.typography.bodyText2.size,
          fontWeight: theme.v2.typography.title1.weight,
          color: theme.v2.color.typography.bodyText2,
        },
        description: {
          fontFamily: theme.v2.typography.bodyText2.family,
          fontSize: theme.v2.typography.bodyText2.size,
          fontWeight: theme.v2.typography.bodyText2.weight,
          color: theme.v2.color.typography.bodyText2,
        },
        action: {
          fontFamily: theme.v2.typography.bodyText2.family,
          fontSize: theme.v2.typography.bodyText2.size,
          fontWeight: theme.v2.typography.bodyText2.weight,
          color: theme.v2.color.typography.bodyText2,
          background: theme.v2.color.background,
          border: `${theme.v2.decoration.border.width.xs} solid #D9D9D9`,
          borderRadius: theme.v2.decoration.border.radius.default,
          padding: theme.v2.static.spacing.size.xs,
        },
      },
      supportInfo: {
        root: {
          border: `${theme.v2.decoration.border.width.xs} solid #D9D9D9`, //TODO: missing token
          borderRadius: `${theme.v2.decoration.border.radius.xxs}`,
          padding: `${theme.v2.static.spacing.size.m}`,
        },
        title: {
          fontFamily: theme.v2.typography.title4.family,
          fontSize: theme.v2.typography.title4.size,
          fontWeight: theme.v2.typography.title4.weight,
          color: theme.v2.color.typography.title4,
        },
        info: {
          fontFamily: theme.v2.typography.bodyText1.family,
          fontSize: theme.v2.typography.bodyText1.size,
          fontWeight: theme.v2.typography.bodyText1.weight,
          color: theme.v2.color.typography.bodyText1,
        },
      },
    };
  },

  getCheckoutErrorsProps(_theme) {
    const theme = {
      v2: merge({}, _theme.v2, _theme.v2.overrides?.checkout?.errors),
    };

    return {
      title: {
        fontFamily: theme.v2.typography.bodyText2.family,
        fontSize: theme.v2.typography.bodyText2.size,
        fontWeight: theme.v2.typography.title2.weight,
        color: theme.v2.color.typography.bodyText2,
      },
      description: {
        fontFamily: theme.v2.typography.bodyText2.family,
        fontSize: theme.v2.typography.bodyText2.size,
        fontWeight: theme.v2.typography.bodyText2.weight,
        color: theme.v2.color.typography.bodyText2,
      },
    };
  },

  getOrderDetailPaymentModalProps(_theme) {
    const theme = {
      v2: merge({}, _theme.v2, _theme.v2.overrides?.checkout?.orderDetailPaymentModal),
    };

    return {
      modal: {
        content: {
          top: {
            lg: "0px",
            md: "70px",
            sm: "70px",
          },
          translateY: {
            lg: "0",
            md: "0",
            sm: "0",
          },
          maxWidth: {
            lg: "612px",
            md: "100%",
            sm: "100%",
          },
          maxHeight: {
            lg: "100vh",
            md: "100vh",
            sm: "100dvh",
          },
          padding: {
            lg: "20px",
            md: "20px",
            sm: "20px 8px",
          },
          borderRadius: {
            lg: "0 0 4px 4px",
            md: "4px 4px 0 0",
            sm: "4px 4px 0 0",
          },
        },
        overlay: {
          color: "#000000",
          opacity: 0.75,
        },
        title: {
          marginTop: theme.v2.static.spacing.m,
          fontFamily: theme.v2.typography.title4.family,
          fontSize: theme.v2.typography.title4.size,
          fontWeight: theme.v2.typography.title4.weight,
          color: theme.v2.color.typography.title4,
        },
        subtitle: {
          fontFamily: theme.v2.typography.bodyText2.family,
          fontSize: theme.v2.typography.bodyText2.size,
          fontWeight: theme.v2.typography.bodyText2.weight,
          color: theme.v2.color.typography.caption1,
        },
        paymentForm: this.getPaymentSectionProps(_theme),
        payButton: {
          root: {
            width: "350px",
          },
        },
      },
    };
  },
};
