import useSite from "src/core/sites/hooks/useSite";
import useRouter from "src/core/common/hooks/useRouter";
import {useEffect, useMemo} from "react";
import useDeal from "@deals/hooks/useDeal";
import usePromotionProducts from "@deals/hooks/usePromotionProducts";
import useThemeConfig from "src/themes/useThemeConfig";
import routes from "src/core/common/routes";
import * as Notifications from "src/core/notifications";
import useServerContext from "src/server/hooks/useServerContext";
import {PrefetchedDataKeys} from "src/server/constants";
import merge from "lodash/merge";
import {mapReward, mapPromotion} from "src/deals/utils";
import Promotion from "src/core/common/models/promotion";
import useCartState from "src/core/checkout/hooks/useCartState";
import useAddItem from "src/core/checkout/hooks/useAddItem";
import Page from "src/core/common/models/page";
import Product from "src/core/common/models/product";
import {populateRelations} from "src/core/api/utils";
import {groupItems} from "src/core/checkout/components/cart/ItemsList/promotionUtils";
import useFlowerTypeIndicatorProps from "src/core/common/hooks/useFlowerTypeIndicatorsProps";

function useBundle(deal, products, site) {
  const [cartState] = useCartState();
  const cart = cartState.data;
  const dealId = deal?.id;
  const repeatItems = deal?.bundleRepeatItems;

  const items = cart ? cart.getItems() : [];

  const bundle = groupItems(items, cart)[dealId] || [];

  const productsInBundle = useMemo(() => {
    return bundle.reduce((acc, item) => {
      const quantity = item.getQuantity();
      const product = products.find(product => product.getId() === item.getProductId());
      if (product) {
        if (repeatItems) {
          for (let i = 0; i < quantity; i++) {
            acc.push(product);
          }
        } else {
          acc.push(product);
        }
      }
      return acc;
    }, []);
  }, [bundle, products]);

  const flowerTypeIndicatorProps = useFlowerTypeIndicatorProps(site);

  const simpleProducts = productsInBundle?.map(product => ({
    imageUrl: product.getMainImage(),
    flowerType: product.getFlowerType(),
    flowerTypeIndicatorProps: flowerTypeIndicatorProps,
  }));

  return {
    bundle,
    currentBundleQuantity: productsInBundle.length,
    bundleProducts: simpleProducts || [],
  };
}

function useBogoDeal({products}) {
  const [cartState] = useCartState();
  const cart = cartState.data;

  const items = cart ? cart.getItems() : [];

  const dealProduct = products.find(product => {
    return items.some(item => item?.getProductId() === product?.getId());
  });

  const itemInCart = items.find(item => {
    return products.some(product => product?.getId() === item?.getProductId());
  });

  const isBogoActive = itemInCart?.getBogoActive();

  const productToAdd = useMemo(() => {
    const result = dealProduct ? dealProduct : products[0];
    if (!result) return null;

    return result.withConversionBreadcrumb("promotion", "deal-detail.add-promo-button");
  }, [dealProduct, products[0]]);

  const {addItem} = useAddItem({
    product: productToAdd,
    quantity: 2,
  });

  return {
    addItem,
    isBogoActive,
  };
}

export default function useDealDetail() {
  const config = useThemeConfig();
  const site = useSite();
  const {dealType, deal: dealSlug} = useRouter().query;
  const router = useRouter();

  const {
    fetchDeal,
    clearDeal,
    state: {deal, loading, error},
  } = useDeal({dealType, dealId: dealSlug});

  const prefetchedDeal = usePrefetchedDeal(dealSlug);

  const dealToUse = deal || prefetchedDeal?.deal;

  const prefetchedPromotionProducts = usePrefetchedPromotionProducts(dealToUse?.id);

  const {fetchPromotionProducts, state: promotionProductsState} = usePromotionProducts();

  const meta = merge(
    {
      skeleton: promotionProductsState.showSkeleton,
      hasMore: promotionProductsState.hasMore,
      loading: promotionProductsState.loading,
      totalCount: promotionProductsState.totalCount,
    },
    prefetchedPromotionProducts?.meta
  );

  const products = useMemo(() => {
    let result = prefetchedPromotionProducts?.products || promotionProductsState.products;
    return result.map(product =>
      product.withConversionBreadcrumb("promotion", "deal-detail.products")
    );
  }, [prefetchedPromotionProducts?.products, promotionProductsState.products]);

  const {bundle, currentBundleQuantity, bundleProducts} = useBundle(
    dealToUse,
    products,
    site
  );

  const {addItem, isBogoActive} = useBogoDeal({products});

  useEffect(() => {
    fetchDeal(dealType, dealSlug);
  }, [dealType, dealSlug]);

  useEffect(() => {
    if (deal) fetchPromotionProducts(deal.id, {reset: true});
  }, [deal]);

  useEffect(() => {
    return clearDeal;
  }, []);

  return {
    isBogoActive: isBogoActive,
    addItem: addItem,
    loading: prefetchedDeal?.meta.loading ?? loading,
    site: site,
    deal: dealToUse,
    bundle: bundle,
    bundleProducts: bundleProducts,
    currentBundleQuantity: currentBundleQuantity,
    onBack: router.safeBack,
    backToDealsPage: () => router.push({pathname: routes.deals}),
    onClickCopyButton: code => {
      navigator.clipboard.writeText(code);
      Notifications.info(`Promo code ${code} copied to clipboard!`);
    },
    notFound: error?.status === 404,
    products: {
      site,
      products: products,
      next: fetchPromotionProducts,
      ProductCardComponent: config.components.ProductCard,
      title: "Save with these products",
      description: "",
      ...meta,
    },
  };
}

function usePrefetchedDeal(dealSlug) {
  const {data} = useServerContext();

  const prefetchedDeal =
    dealSlug && data[PrefetchedDataKeys.DEAL]?.slug === dealSlug
      ? data[PrefetchedDataKeys.DEAL]
      : null;

  if (!prefetchedDeal) return null;

  const deal =
    prefetchedDeal instanceof Promotion
      ? mapPromotion(prefetchedDeal)
      : mapReward(prefetchedDeal);

  return {
    deal,
    meta: {
      error: null,
      loading: false,
    },
  };
}

function usePrefetchedPromotionProducts(dealId) {
  const {data} = useServerContext();

  const prefetchedProducts =
    dealId && data[PrefetchedDataKeys.PROMOTION_PRODUCTS]?.params?.promotion === dealId
      ? data[PrefetchedDataKeys.PROMOTION_PRODUCTS]?.data
      : null;

  const products = useMemo(() => {
    if (!Array.isArray(prefetchedProducts?.data) || !prefetchedProducts.data.length)
      return null;
    return new Page({
      meta: prefetchedProducts?.meta,
      objects: prefetchedProducts?.data.map(
        element => new Product(populateRelations(element, prefetchedProducts.included))
      ),
    });
  }, [prefetchedProducts]);

  if (!prefetchedProducts) {
    return null;
  }

  const meta = prefetchedProducts?.meta;

  return {
    products: products,
    meta: {
      skeleton: false,
      hasMore: meta?.total_count > meta?.limit + meta?.offset,
      loading: false,
      totalCount: meta?.total_count,
    },
  };
}
