import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import Skeleton from "react-loading-skeleton";
import spacing from "@ui/utils/spacing";
import media from "@ui/utils/media";
import renderNodeOrComponent from "@ui/utils/RenderNodeOrComponent";
import ConfigurableImage from "@ui/components/ConfigurableImage";
import PotencyTag from "@ui/components/PotencyTag/PotencyTag";
import ReadMore from "@ui/components/ReadMore";
import QuantityPicker from "@ui/components/ProductDetail/BigQuantityPicker";
import Button from "@ui/components/Button";
import PromotionIndicator from "@ui/components/ProductCard/PromotionIndicator";
import InStockIcon from "@ui/components/ProductDetail/InStockIcon";
import makeFlowerTypeIndicator from "@ui/components/FlowerTypeIndicator/makeFlowerTypeIndicator";
import SaleIndicator from "@ui/components/ProductCard/SaleIndicator";

export default function FlightProductInfo({
  styles,
  hasEmptyPlaceholder,
  emptyPlaceholderProps,
  isSkeleton,
  image,
  LinkComponent,
  brand,
  productName,
  separator,
  sizes,
  mainTags,
  description,
  price,
  discountedPrice,
  validForSale,
  quantityPicker,
  secondaryTags,
  FlowerTypeIndicatorComponent,
  PotencyTagComponent,
  PromotionIndicatorComponent,
  promotionText,
  inStock,
  NavigationActionsComponent,
  ActionsComponent,
  onSale,
  SaleIndicatorComponent,
  type,
  handleCustomAmountChange,
  ...props
}) {
  const _styles = merge({}, defaultStyles, styles);
  const {brandName, brandPath} = {...brand};
  const {flowerType, hideNameOnSmallerScreens, flowerTypeIndicatorProps, tags} = {
    ...mainTags,
  };

  if (hasEmptyPlaceholder) {
    return (
      <EmptyPlaceholder
        styles={_styles.emptyPlaceholder}
        onClick={emptyPlaceholderProps.onEmptyPlaceholderButtonClickHandler}
        image={emptyPlaceholderProps.emptyPlaceholderImage}
      />
    );
  }

  if (isSkeleton) {
    return (
      <FlightProductInfoSkeleton
        styles={_styles}
        sizes={sizes}
        separator={separator}
        secondaryTags={secondaryTags}
      />
    );
  }

  return (
    <FlightProductInfoContainer styles={_styles.root}>
      {NavigationActionsComponent}
      {image && (
        <ImageContainer styles={_styles.image}>
          {flowerType && (
            <FlowerTypeIndicatorContainer styles={_styles.flowerTypeIndicator}>
              <FlowerTypeIndicatorComponent
                styles={_styles.flowerTypeIndicator}
                flowerType={flowerType}
                hideNameOnSmallerScreens={hideNameOnSmallerScreens}
                flowerTypeIndicatorProps={flowerTypeIndicatorProps}
              />
            </FlowerTypeIndicatorContainer>
          )}
          {onSale && SaleIndicatorComponent && (
            <SaleIndicatorContainer styles={_styles.saleIndicator}>
              {renderNodeOrComponent(SaleIndicatorComponent, {
                styles: _styles.saleIndicator,
              })}
            </SaleIndicatorContainer>
          )}
          <Image
            styles={_styles.image}
            source={image.url}
            height={_styles.image.height.lg.replace("px", "")}
            alt={image.name}
          />
          {promotionText && (
            <PromotionIndicatorComponent
              promotionText={promotionText}
              styles={_styles.promotionIndicator}
            />
          )}
        </ImageContainer>
      )}
      <ProductDetailContainer styles={_styles.productDetail}>
        <ProductName styles={_styles.name}>{productName}</ProductName>

        {brandName && (
          <BrandNamePotencyTagsContainer styles={_styles.brand}>
            <LinkComponent to={brandPath} styles={_styles.brand}>
              <BrandName styles={_styles.brand}>
                {brandName} {tags && "|"}{" "}
              </BrandName>
            </LinkComponent>
            {tags &&
              tags.map((tag, key) => (
                <PotencyTag key={key} styles={_styles.mainTags} text={tag} />
              ))}
          </BrandNamePotencyTagsContainer>
        )}
        {separator && separator.top && <Separator styles={_styles.separator} />}
        {description && (
          <DescriptionContainer>
            <DescriptionLabel styles={_styles.descriptionLabel}>
              Description:
            </DescriptionLabel>
            <ReadMore
              styles={_styles.description}
              text={description.text}
              readMoreText={"Full Description"}
              hideText={description.hideText}
              ReadMoreButtonComponent={description.ReadMoreButtonComponent}
            />
          </DescriptionContainer>
        )}
        {price && (
          <PriceDisplayContainer styles={_styles.priceDisplay}>
            <PriceDisplay>
              <PriceDisplayPrice styles={_styles.priceDisplay.price}>
                {price}
              </PriceDisplayPrice>
              {sizes.content[0] !== "" && (
                <PriceDisplaySize styles={_styles.priceDisplay.size}>
                  {"-"} {sizes.content.map(s => s.toString()).join(", ")}
                </PriceDisplaySize>
              )}
            </PriceDisplay>
          </PriceDisplayContainer>
        )}

        {inStock && (
          <InStockContainer styles={_styles.inStockContainer}>
            <InStockIcon
              width={"24"}
              height={"20"}
              color={_styles.inStockContainer.color}
            />
            <span>In Stock</span>
          </InStockContainer>
        )}
        {validForSale ? (
          renderNodeOrComponent(ActionsComponent || <QuantityPicker />, {
            ...quantityPicker,
            type,
            handleCustomAmountChange,
            isFixedToBottomOnMobile: false,
            quantityText: true,
            withBorder: true,
            cartIcon: "empty",
            styles: _styles.quantityPicker,
          })
        ) : (
          <NotAvailable styles={_styles.notAvailable}>
            This product is no longer available.
          </NotAvailable>
        )}
        {separator && separator.bottom && <Separator styles={_styles.separator} />}
        {secondaryTags && (
          <SecondaryTagsContainer styles={_styles.secondaryTagsContainer}>
            {secondaryTags.map((tag, index) => (
              <SecondaryTag key={`secondary-tag-${index}`} styles={_styles.secondaryTags}>
                <LinkComponent to={tag.url} keepQuery={tag.keepQuery}>
                  {tag.name}
                </LinkComponent>
              </SecondaryTag>
            ))}
          </SecondaryTagsContainer>
        )}
      </ProductDetailContainer>
    </FlightProductInfoContainer>
  );
}

function EmptyPlaceholder({styles, onClick, image}) {
  return (
    <EmptyPlaceholderContainer styles={styles}>
      <img src={image} alt="Product unavailable" />
      <h1>Sorry, this product is no longer available</h1>
      <Button
        styles={{root: {width: "100%"}}}
        label="Check our other products"
        onClick={onClick}
      />
    </EmptyPlaceholderContainer>
  );
}

function FlightProductInfoSkeleton({styles, separator, sizes}) {
  return (
    <FlightProductInfoContainer styles={styles.root}>
      <ImageContainer styles={styles.image}>
        <div style={{display: "flex", justifyContent: "center"}}>
          <CustomSkeleton styles={styles.skeleton.image} />
        </div>
      </ImageContainer>
      <ProductDetailContainer styles={styles.productDetail}>
        <CustomSkeleton styles={styles.skeleton.productInfo} />
        <CustomSkeleton styles={styles.skeleton.productInfo} />
        {sizes && sizes.position === "top" && (
          <CustomSkeleton styles={styles.skeleton.productInfo} />
        )}
        {separator && separator.top && <Separator styles={styles.separator} />}
        <CustomSkeleton styles={styles.skeleton.description} />
        <CustomSkeleton styles={styles.skeleton.quantityPicker} />
        {separator && separator.bottom && <Separator styles={styles.separator} />}
        <SecondaryTagsContainer styles={styles.secondaryTagsContainer}>
          {[...new Array(styles.skeleton.tags.count)].map((v, index) => (
            <CustomSkeleton key={index} styles={styles.skeleton.tags} />
          ))}
        </SecondaryTagsContainer>
      </ProductDetailContainer>
    </FlightProductInfoContainer>
  );
}

const EmptyPlaceholderContainer = styled.div`
  box-sizing: border-box;
  padding: ${spacing(2)};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  > *:not(:last-child) {
    margin-bottom: 20px;
  }

  > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
  }

  > h1 {
    margin-top: 0;
    font-weight: ${({styles}) => styles.fontWeight};
    ${media.up("lg")} {
      font-size: ${({styles}) => styles.fontSize.lg};
    }
    ${media.down("md")} {
      font-size: ${({styles}) => styles.fontSize.md};
    }
    ${media.down("sm")} {
      font-size: ${({styles}) => styles.fontSize.sm};
    }
  }

  > :last-child {
    width: 100%;
  }
`;

const CustomSkeleton = styled(Skeleton).attrs(() => ({
  className: "product-info__skeleton",
}))`
  height: ${({styles}) => styles.height.lg};
  max-width: ${({styles}) => styles.maxWidth.lg};
  min-width: ${({styles}) => styles.minWidth.lg};
  border-radius: ${({styles}) => styles.borderRadius};
  ${media.down("md")} {
    height: ${({styles}) => styles.height.md};
    max-width: ${({styles}) => styles.maxWidth.md};
    min-width: ${({styles}) => styles.minWidth.md};
  }
  ${media.down("sm")} {
    height: ${({styles}) => styles.height.sm};
    max-width: ${({styles}) => styles.maxWidth.sm};
    min-width: ${({styles}) => styles.minWidth.sm};
  }
`;

const FlightProductInfoContainer = styled.div.attrs(() => ({
  className: "product-info__container",
}))`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  height: auto;
  column-gap: 30px;
  margin-bottom: ${({styles}) => styles.marginBottom};

  ${media.up("lg")} {
    padding: ${({styles}) => styles.padding.lg};
  }
  ${media.down("md")} {
    flex-direction: column;
    row-gap: 30px;
    padding: ${({styles}) => styles.padding.md};
  }
  ${media.down("sm")} {
    padding: ${({styles}) => styles.padding.sm};
    row-gap: 10px;
    padding-top: 10px;
  }
`;

const ImageContainer = styled.div.attrs(() => ({
  className: "product-info__image-container",
}))`
  display: inline;
  align-self: center;
  border-radius: 4px;
  position: relative;
`;

const Image = styled(ConfigurableImage).attrs(() => ({
  className: "product-info__image",
}))`
  filter: drop-shadow(0px 0px 10px #e8e8e8);
  border: 1px solid #e8e8e8;
  object-fit: ${({styles}) => styles.objectFit};
  border-radius: ${({styles}) => styles.borderRadius};
  ${media.up("lg")} {
    height: ${({styles}) => styles.height.lg};
    width: ${({styles}) => styles.height.lg};
  }
  ${media.down("md")} {
    height: ${({styles}) => styles.height.md};
    width: ${({styles}) => styles.height.md};
  }
  ${media.down("sm")} {
    height: ${({styles}) => styles.height.sm};
    width: ${({styles}) => styles.height.sm};
  }
`;

const ProductDetailContainer = styled.div.attrs(() => ({
  className: "product-info__product-detail-container",
}))`
  font-family: ${({styles}) => styles.fontFamily};
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  row-gap: ${({styles}) => styles.rowGap};
  padding: 15px;
`;

const BrandName = styled.span.attrs(() => ({
  className: "product-info__brand",
}))`
  font-family: ${({styles}) => styles.fontFamily};
  color: ${({styles}) => styles.color};
  font-weight: ${({styles}) => styles.fontWeight};
  text-transform: ${({styles}) => styles.textTransform};
  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }

  &:hover {
    text-decoration: ${({styles}) => styles.hoverTextDecoration};
  }
`;

const ProductName = styled.span.attrs(() => ({
  className: "product-info__product-name",
}))`
  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  line-height: ${({styles}) => styles.lineHeight};
  text-transform: ${({styles}) => styles.textTransform};
  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
`;

const Separator = styled.div.attrs(() => ({
  className: "product-info__separator",
}))`
  background-color: ${({styles}) => styles.color};
  height: ${({styles}) => styles.height};
  max-height: ${({styles}) => styles.height};
  margin: ${({styles}) => styles.margin};
`;

const PriceDisplayContainer = styled.div.attrs(() => ({
  className: "product-info__price-display-container",
}))`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

const PriceDisplay = styled.div.attrs(() => ({
  className: "product-info__price-display",
}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
`;

const PriceDisplayPrice = styled.div.attrs(() => ({
  className: "product-info__price-display-price",
}))`
  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const PriceDisplaySize = styled.div.attrs(() => ({
  className: "product-info__price-display-size",
}))`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 320px;
  color: ${({styles}) => styles.color};
  font-weight: ${({styles}) => styles.fontWeight};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const NotAvailable = styled.span.attrs(() => ({
  className: "product-info__not-available",
}))`
  color: ${({styles}) => styles.color};
  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const SecondaryTagsContainer = styled.div.attrs(() => ({
  className: "product-info__secondary-tags-container",
}))`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  column-gap: ${({styles}) => styles.columnGap};
  row-gap: ${({styles}) => styles.rowGap};
  padding-top: ${({styles}) => styles.paddingTop};

  font-weight: ${({styles}) => styles.fontWeight};
  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const SecondaryTag = styled.div.attrs(() => ({
  className: "product-info__secondary-tag",
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({styles}) => styles.border};
  border-radius: ${({styles}) => styles.borderRadius};
  background-color: ${({styles}) => styles.backgroundColor};
  line-height: ${({styles}) => styles.lineHeight};
  text-transform: ${({styles}) => styles.textTransform};
  font-weight: ${({styles}) => styles.fontWeight};
  font-size: ${({styles}) => styles.fontSize.lg};

  ${media.up("lg")} {
    padding: ${({styles}) => styles.padding.lg};
  }
  ${media.down("md")} {
    padding: ${({styles}) => styles.padding.md};
  }
  ${media.down("sm")} {
    padding: ${({styles}) => styles.padding.sm};
    font-size: ${({styles}) => styles.fontSize.sm};
  }

  a {
    text-decoration: none;
    color: ${({styles}) => styles.color};
  }
`;
const BrandNamePotencyTagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: baseline;
  text-align: center;
`;
const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const DescriptionLabel = styled.div.attrs(() => ({
  className: "product-info__description-label",
}))`
  color: ${({styles}) => styles.color};
  font-weight: ${({styles}) => styles.fontWeight};
  font-size: ${({styles}) => styles.fontSize.lg};
  padding-right: 4px;

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const InStockContainer = styled.div.attrs(() => ({
  className: "product-info__in-stock-container",
}))`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  column-gap: ${({styles}) => styles.columnGap};
  row-gap: ${({styles}) => styles.rowGap};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const FlowerTypeIndicatorContainer = styled.div.attrs(() => ({
  className: "product-detail__flower-type-indicator-container",
  "data-keep-cart": "true",
}))`
  position: absolute;
  top: ${({styles}) => styles.position.lg.top};
  left: ${({styles}) => styles.position.lg.left};
  z-index: 4;

  ${media.down("md")} {
    top: ${({styles}) => styles.position.md.top};
    left: ${({styles}) => styles.position.md.left};
  }
  ${media.down("sm")} {
    top: ${({styles}) => styles.position.sm.top};
    left: ${({styles}) => styles.position.sm.left};
  }
`;

const SaleIndicatorContainer = styled.div.attrs(() => ({
  className: "product-detail__flower-type-indicator-container",
  "data-keep-cart": "true",
}))`
  position: absolute;
  top: ${({styles}) => styles.position.lg.top};
  right: ${({styles}) => styles.position.lg.right};
  z-index: 4;

  ${media.down("md")} {
    top: ${({styles}) => styles.position.md.top};
    right: ${({styles}) => styles.position.md.right};
  }
  ${media.down("sm")} {
    top: ${({styles}) => styles.position.sm.top};
    right: ${({styles}) => styles.position.sm.right};
  }
`;

FlightProductInfo.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    emptyPlaceholder: PropTypes.shape({
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    skeleton: PropTypes.shape({
      image: PropTypes.shape({
        height: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        minWidth: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        maxWidth: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        borderRadius: PropTypes.string,
      }),
    }),
    image: PropTypes.shape({
      objectFit: PropTypes.string,
      borderRadius: PropTypes.string,
      height: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    productDetail: PropTypes.shape({
      fontFamily: PropTypes.string,
      rowGap: PropTypes.string,
    }),
    brand: PropTypes.shape({
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
      textTransform: PropTypes.string,
      textDecoration: PropTypes.string,
      hoverTextDecoration: PropTypes.string,
    }),
    name: PropTypes.shape({
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
      textTransform: PropTypes.string,
      lineHeight: PropTypes.string,
    }),
    size: PropTypes.shape({
      margin: PropTypes.string,
      maxWidth: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      color: PropTypes.string,
    }),
    separator: PropTypes.shape({
      color: PropTypes.string,
      height: PropTypes.string,
      margin: PropTypes.string,
    }),
    flowerTypeIndicator: PropTypes.object,
    mainTags: PropTypes.shape({
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      backgroundColor: PropTypes.string,
      border: PropTypes.string,
      borderRadius: PropTypes.string,
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      height: PropTypes.string,
    }),
    description: PropTypes.shape({
      root: PropTypes.shape({
        margin: PropTypes.string,
        lineHeight: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
      }),
      text: PropTypes.shape({
        color: PropTypes.string,
        maxLines: PropTypes.shape({
          lg: PropTypes.number,
          md: PropTypes.number,
          sm: PropTypes.number,
        }),
        textAlign: PropTypes.string,
      }),
      button: PropTypes.shape({
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
      }),
    }),
    priceDisplay: PropTypes.shape({
      price: PropTypes.shape({
        color: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      size: PropTypes.shape({
        color: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }),
    notAvailable: PropTypes.shape({
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      color: PropTypes.string,
    }),
    secondaryTagsContainer: PropTypes.shape({
      columnGap: PropTypes.string,
      rowGap: PropTypes.string,
      paddingTop: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    secondaryTags: PropTypes.shape({
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      border: PropTypes.string,
      borderRadius: PropTypes.string,
      color: PropTypes.string,
      backgroundColor: PropTypes.string,
      lineHeight: PropTypes.string,
      textTransform: PropTypes.string,
    }),
    inStockContainer: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontSize: PropTypes.string,
      color: PropTypes.string,
    }),
  }),
  hasEmptyPlaceholder: PropTypes.bool,
  emptyPlaceholderProps: PropTypes.shape({
    onEmptyPlaceholderButtonClickHandler: PropTypes.func,
    emptyPlaceholderImage: PropTypes.string,
  }),
  isSkeleton: PropTypes.bool,
  image: PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
  }),
  LinkComponent: PropTypes.elementType,
  brand: PropTypes.shape({
    brandName: PropTypes.string,
    brandPath: PropTypes.string,
  }),
  productName: PropTypes.string,
  sizes: PropTypes.shape({
    position: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.string),
  }),
  separator: PropTypes.shape({
    top: PropTypes.bool,
    bottom: PropTypes.bool,
  }),
  mainTags: PropTypes.shape({
    flowerTypeBefore: PropTypes.bool,
    flowerType: PropTypes.shape({
      icon: PropTypes.elementType,
      color: PropTypes.string,
      name: PropTypes.string,
    }),
    hideNameOnSmallerScreens: PropTypes.bool,
    flowerTypeIndicatorProps: PropTypes.shape({
      getColor: PropTypes.func,
      getBackground: PropTypes.func,
    }),
  }),
  description: PropTypes.shape({
    text: PropTypes.string,
    readMoreText: PropTypes.string,
    hideText: PropTypes.string,
    ReadMoreButtonComponent: PropTypes.elementType,
  }),
  price: PropTypes.string,
  discountedPrice: PropTypes.string,
  validForSale: PropTypes.bool,
  promotionText: PropTypes.string,
  quantityPicker: PropTypes.object,
  inStock: PropTypes.bool,
  secondaryTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      keepQuery: PropTypes.bool,
    })
  ),
  FlowerTypeIndicatorComponent: PropTypes.elementType,
  PotencyTagComponent: PropTypes.elementType,
  PromotionIndicatorComponent: PropTypes.elementType,
  NavigationActionsComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.elementType,
  ]),
  ActionsComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  SaleIndicatorComponent: PropTypes.elementType,
  onSale: PropTypes.bool,
};

const defaultStyles = {
  root: {
    padding: {
      lg: "30px",
      md: "25px 0",
      sm: "25px 0",
    },
  },
  emptyPlaceholder: {
    fontSize: {
      lg: "24px",
      md: "24px",
      sm: "24px",
    },
    fontWeight: "300",
  },
  skeleton: {
    image: {
      height: {
        lg: "484px",
        md: "300px",
        sm: "300px",
      },
      minWidth: {
        lg: "484px",
        md: "300px",
        sm: "300px",
      },
      maxWidth: {
        lg: "484px",
        md: "300px",
        sm: "300px",
      },
      borderRadius: "10px",
    },
    productInfo: {
      height: {
        lg: "20px",
        md: "20px",
        sm: "20px",
      },
      minWidth: {
        lg: "100%",
        md: "100%",
        sm: "220px",
      },
      maxWidth: {
        lg: "100%",
        md: "100%",
        sm: "100%",
      },
    },
    tags: {
      count: 3,
      height: {
        lg: "20px",
        md: "20px",
        sm: "20px",
      },
      minWidth: {
        lg: "80px",
        md: "80px",
        sm: "80px",
      },
      maxWidth: {
        lg: "80px",
        md: "80px",
        sm: "80px",
      },
      borderRadius: "12px",
    },
    description: {
      height: {
        lg: "120px",
        md: "120px",
        sm: "80px",
      },
      minWidth: {
        lg: "100%",
        md: "100%",
        sm: "100%",
      },
      maxWidth: {
        lg: "100%",
        md: "100%",
        sm: "100%",
      },
    },
    quantityPicker: {
      height: {
        lg: "100px",
        md: "100px",
        sm: "100px",
      },
      minWidth: {
        lg: "100%",
        md: "100%",
        sm: "100%",
      },
      maxWidth: {
        lg: "100%",
        md: "100%",
        sm: "100%",
      },
    },
  },
  image: {
    objectFit: "contain",
    borderRadius: "10px",
    height: {
      lg: "482px",
      md: "300px",
      sm: "300px",
    },
  },
  productDetail: {
    fontFamily: "sans-serif",
    rowGap: "10px",
  },
  brand: {
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: "400",
    color: "#020202",
    textTransform: "uppercase",
    textDecoration: "none",
    hoverTextDecoration: "none",
  },
  name: {
    fontSize: {
      lg: "18px",
      md: "18px",
      sm: "18px",
    },
    fontWeight: "400",
    lineHeight: "1.4",
    color: "#020202",
    textTransform: "none",
  },
  size: {
    maxWidth: "320px",
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "16px",
    },
    fontWeight: "inherit",
    color: "#757575",
  },
  separator: {
    color: "#EBEBEB",
    height: "1px",
    margin: "16px 0 5px",
  },
  flowerTypeIndicator: {
    height: "42px",
    width: "42px",
    position: {
      lg: {
        top: "60px",
        left: "65px",
      },
      md: {
        top: "60px",
        left: "300px",
      },
      sm: {
        top: "42px",
        left: "26px",
      },
    },
    fontFamily: "sans-serif",
    fontSize: {
      lg: "12px",
      md: "12px",
      sm: "12px",
    },
    fontWeight: "600",
    border: ".5px solid #272727",
    padding: "2px 6px",
    textTransform: "uppercase",
  },
  mainTags: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "10px",
      md: "10px",
      sm: "10px",
    },
    fontWeight: "400",
    padding: {
      lg: "2px 4px",
      md: "2px 4px",
      sm: "",
    },
  },
  saleIndicator: {
    position: {
      lg: {
        top: "",
        right: "",
      },
      md: {
        top: "",
        right: "",
      },
      sm: {
        top: "",
        right: "",
      },
    },
    padding: "3px 10px",
    fontSize: {
      lg: "8px",
      md: "8px",
      sm: "8px",
    },
    backgroundColor: "red",
    fontFamily: "sans-serif",
    borderRadius: "8px",
    margin: {
      lg: "",
      md: "",
      sm: "",
    },
  },
  descriptionLabel: {
    color: "#000000",
    fontWeight: "700",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
  },
  description: {
    root: {
      margin: "16px 0",
      lineHeight: {
        lg: "180%",
        md: "180%",
        sm: "180%",
      },
    },
    text: {
      color: "#000",
      maxLines: {
        lg: 8,
        md: 5,
        sm: 4,
      },
      textAlign: "left",
    },
    button: {
      fontWeight: "600",
      color: "#000",
    },
  },
  priceDisplay: {
    price: {
      color: "#888888",
      fontSize: {
        lg: "12px",
        md: "12px",
        sm: "12px",
      },
      fontWeight: "700",
    },
    discountedPrice: {
      color: "#D0007D",
      fontSize: {
        lg: "16px",
        md: "16px",
        sm: "16px",
      },
      fontWeight: "700",
    },
    size: {
      color: "#000000",
      fontSize: {
        lg: "13px",
        md: "13px",
        sm: "13px",
      },
      fontWeight: "700",
    },
  },
  quantityPicker: {
    root: {
      fontFamily: "sans-serif",
      width: {
        lg: "",
        md: "",
        sm: "",
      },
      padding: {
        lg: "",
        md: "",
        sm: "",
      },
    },
    quantityPicker: {
      quantityBorder: "1px solid #8A8A8A",
      quantityBorderRadius: "8px",
      backgroundColor: "#FAFAFA",
      quantityColor: "#000",
      quantityFamily: "Arial",
      quantityWeight: "400",
      borderRadius: "10px",
      quantitySize: {
        lg: "17px",
        md: "17px",
        sm: "17px",
      },
      quantityTextFontSize: "11px",
      quantityTextWeight: "400",
      quantityTextColor: "#000",
      controllersWidth: {
        lg: "28px",
        md: "28px",
        sm: "28px",
      },
      controllersHeight: {
        lg: "28px",
        md: "28px",
        sm: "28px",
      },
      controllersColor: "#8D8D8D",
      minusIconSize: "18px",
      plusIconSize: "18px",
      removeItemIcon: {
        size: "18px",
        color: "#f07178",
      },
    },
    addToCartButton: {
      backgroundColor: "#000",
      color: "#FFF",
      border: "none",
      borderRadius: "4px",
      mobileMaxHeight: "70px",
      fontSize: {
        lg: "16px",
        md: "16px",
        sm: "16px",
      },
      fontWeight: "700",
      padding: "0.3em 1em",
      inCartIconSize: "22px",
    },
    giftCardSelector: {
      inputPlaceholderColor: "",
      marginLeft: {
        lg: "16px",
        md: "16px",
        sm: "16px",
      },
      fontSize: {
        lg: "14px",
        md: "14px",
        sm: "14px",
      },
      borderColor: "#e0e0e0",
      color: "#333333",
      selectedBackgroundColor: "#37B2DC",
      selectedColor: "#fafafa",
      backgroundColor: "#fafafa",
      borderRadius: "5px",
    },

    price: {
      fontSize: {
        lg: "32px",
        md: "32px",
        sm: "32px",
      },
      fontWeight: "700",
      color: "#000",
      backgroundColor: "#fafafa",
      borderRadius: "4px",
    },
    savings: {
      fontSize: {
        lg: "16px",
        md: "16px",
        sm: "16px",
      },
      fontWeight: "300",
      color: "#0b8535",
    },
    weightSelector: {
      marginTop: {
        lg: "16px",
        md: "16px",
        sm: "16px",
      },
      borderRadius: "4px",
      fontSize: {
        lg: "14px",
        md: "14px",
        sm: "14px",
      },
      color: "#333333",
      backgroundColor: "#fafafa",
    },
    fixedToBottomContainer: {
      background:
        "linear-gradient(180deg, rgba(255, 255, 255, 0) -2.56%, #ffffff 34.62%)",
    },
  },
  notAvailable: {
    fontSize: {
      lg: "18px",
      md: "18px",
      sm: "18px",
    },
    color: "#f07178",
  },
  inStockContainer: {
    fontWeight: "400",
    fontSize: "18px",
    fontFamily: "DIN Next LT Pro",
    color: "#249711",
  },
  secondaryTagsContainer: {
    paddingTop: "8px",
    columnGap: "8px",
    rowGap: "8px",
    fontSize: {
      lg: "12px",
      md: "12px",
      sm: "12px",
    },
    fontWeight: "600",
  },
  secondaryTags: {
    padding: {
      lg: "2px 6px",
      md: "2px 6px",
      sm: "2px 6px",
    },
    border: "0",
    borderRadius: "10px",
    color: "#757575",
    backgroundColor: "#F2F2F2",
    lineHeight: "15px",
    textTransform: "lowercase",
  },
  promotionIndicator: {
    width: "243px",
    borderRadius: "10px",
    fontSize: {
      lg: "12px",
      md: "12px",
      sm: "12px",
    },
    backgroundColor: "#D0007D",
    color: "#fff",
    fontFamily: "sans-serif",
    margin: {
      lg: "10px",
      md: "",
      sm: "",
    },
    padding: {
      lg: "6px",
      md: "",
      sm: "",
    },
    position: {
      top: "520px",
      left: "200px",
    },
    top: "300px",
    left: "60px",
  },
};

FlightProductInfo.defaultProps = {
  styles: defaultStyles,
  LinkComponent: ({to, keepQuery, children}) => <a href={to}>{children}</a>,
  PotencyTagComponent: PotencyTag,
  FlowerTypeIndicatorComponent: () => makeFlowerTypeIndicator("airplane"),
  PromotionIndicatorComponent: PromotionIndicator,
  withBorder: true,
  NavigationActionsComponent: null,
  ActionsComponent: null,
  SaleIndicatorComponent: SaleIndicator,
};
