import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import media from "@ui/utils/media";
import spacing from "@ui/utils/spacing";
import ProductCardSkeleton from "@ui/components/ProductCard/ProductCardSkeleton";
import ConfigurableImage from "@ui/components/ConfigurableImage";
import SimpleFlowerTypeIndicator from "@ui/components/FlowerTypeIndicator/SimpleFlowerTypeIndicator";
import SaleIndicator from "@ui/components/ProductCard/SaleIndicator";
import PotencyTags from "@ui/components/ProductCard/PotencyTags";
import PotencyTag from "@ui/components/PotencyTag/PotencyTag";
import renderNodeOrComponent from "@ui/utils/RenderNodeOrComponent";

function HorizontalProductCard({
  styles,
  skeleton,
  loading,
  zoomOnHover,
  productDetailUrl,
  imageUrl,
  onSale,
  salesIndicatorPosition,
  flowerType,
  hideFlowerIndicatorNameOnSmallerScreens,
  flowerTypeIndicatorProps = {},
  brandName,
  brandUrl,
  name,
  sizes,
  showSizes,
  potencyTags,
  LinkComponent,
  FlowerTypeIndicatorComponent,
  SaleIndicatorComponent,
  PotencyTagComponent,
  ActionsComponent,
}) {
  const hasProductSizes = showSizes && sizes && sizes.length > 0;
  const hasPotencyTags = potencyTags && potencyTags.length > 0;

  const _styles = merge({}, defaultStyles, styles);
  const saleIndicatorStyles = {..._styles.saleIndicator, position: "unset"};

  if (skeleton) {
    return <ProductCardSkeleton styles={_styles} />;
  }

  return (
    <Container styles={_styles.root} loading={loading} zoomOnHover={zoomOnHover}>
      <ProductImageContainer styles={_styles.image}>
        <LinkComponent to={productDetailUrl}>
          <ProductImage
            styles={_styles.image}
            source={imageUrl}
            height={_styles.image.height.lg.replace("px", "")}
            alt={name}
          />
          <IndicatorsContainer styles={_styles.flowerTypeIndicator}>
            {flowerType && (
              <FlowerTypeIndicatorContainer>
                <FlowerTypeIndicatorComponent
                  styles={_styles.flowerTypeIndicator}
                  flowerType={flowerType}
                  flowerTypeIndicatorProps={flowerTypeIndicatorProps}
                  hideNameOnSmallerScreens={hideFlowerIndicatorNameOnSmallerScreens}
                />
              </FlowerTypeIndicatorContainer>
            )}
            {onSale &&
              renderNodeOrComponent(SaleIndicatorComponent, {
                styles: saleIndicatorStyles,
                position: salesIndicatorPosition,
              })}
          </IndicatorsContainer>
        </LinkComponent>
      </ProductImageContainer>
      <Content>
        <ProductInfo styles={_styles.info}>
          {brandName && (
            <LinkComponent to={brandUrl}>
              <BrandName styles={_styles.brandName}>{brandName}</BrandName>
            </LinkComponent>
          )}
          <LinkComponent to={productDetailUrl}>
            <Name styles={_styles.productName}>{name}</Name>
          </LinkComponent>
          {hasProductSizes && (
            <ProductSize styles={_styles.productSizes}>
              {sizes.filter(s => typeof s === "string").join(", ")}
            </ProductSize>
          )}
          {hasPotencyTags && (
            <PotencyTags
              styles={_styles.potencyTag}
              potencyTags={potencyTags}
              PotencyTagComponent={PotencyTagComponent}
            />
          )}
        </ProductInfo>
        <ProductActions styles={_styles.actionsContainer}>
          {renderNodeOrComponent(ActionsComponent, {
            styles: _styles.actions,
          })}
        </ProductActions>
      </Content>
    </Container>
  );
}

const IndicatorsContainer = styled.div.attrs(() => ({
  className: "product-card-horizontal__indicator-container",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 8px;
  position: absolute;
  top: ${({styles}) => styles.position.top};
  bottom: ${({styles}) => styles.position.bottom};
  left: ${({styles}) => styles.position.left};
  right: ${({styles}) => styles.position.right};
`;

const Container = styled.div.attrs(() => ({
  className: "product-card-horizontal__container",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background-color: #fff;
  opacity: ${({loading}) => (loading ? 0.75 : 1)};
  column-gap: ${({styles}) => styles.columnGap};
  border: ${({styles}) => styles.border};
  border-radius: ${({styles}) => styles.borderRadius};
  box-shadow: ${({styles}) => styles.boxShadow};
  font-family: ${({styles}) => styles.fontFamily};

  ${media.up("lg")} {
    height: ${({styles}) => styles.height.lg};
    min-width: ${({styles}) => styles.minWidth.lg};
    max-width: ${({styles}) => styles.maxWidth.lg};
    padding: ${({styles}) => styles.padding.lg};
  }

  ${media.down("md")} {
    height: ${({styles}) => styles.height.md};
    min-width: ${({styles}) => styles.minWidth.md};
    max-width: ${({styles}) => styles.maxWidth.md};
    padding: ${({styles}) => styles.padding.md};
  }

  ${media.down("sm")} {
    height: ${({styles}) => styles.height.sm};
    min-width: ${({styles}) => styles.minWidth.sm};
    max-width: ${({styles}) => styles.maxWidth.sm};
    padding: ${({styles}) => styles.padding.sm};
  }

  &:hover {
    ${media.up("md")} {
      transform: scale(${({zoomOnHover}) => (zoomOnHover ? 1.05 : 1)});
      border: ${({styles}) => styles.hoverBorder};
    }
  }
`;

const ProductImageContainer = styled.div.attrs(() => ({
  className: "product-card-horizontal__image-container",
  "data-keep-cart": "true",
}))`
  display: flex;
  align-items: center;

  ${media.up("lg")} {
    padding: ${({styles}) => styles.padding.lg};
  }

  ${media.down("md")} {
    padding: ${({styles}) => styles.padding.md};
  }

  ${media.down("sm")} {
    padding: ${({styles}) => styles.padding.sm};
  }

  > a {
    display: flex;
    position: relative;
  }
`;

const ProductImage = styled(ConfigurableImage).attrs(() => ({
  className: "product-card-horizontal__image",
  "data-keep-cart": "true",
}))`
  object-fit: ${({styles}) => styles.objectFit};
  border-radius: ${({styles}) => styles.borderRadius};

  ${media.up("lg")} {
    height: ${({styles}) => styles.height.lg};
    width: ${({styles}) => styles.height.lg};
    min-width: ${({styles}) => styles.minWidth.lg};
    max-width: ${({styles}) => styles.maxWidth.lg};
  }

  ${media.down("md")} {
    height: ${({styles}) => styles.height.md};
    width: ${({styles}) => styles.height.md};
    min-width: ${({styles}) => styles.minWidth.md};
    max-width: ${({styles}) => styles.maxWidth.md};
  }

  ${media.down("sm")} {
    height: ${({styles}) => styles.height.sm};
    width: ${({styles}) => styles.height.sm};
    min-width: ${({styles}) => styles.minWidth.sm};
    max-width: ${({styles}) => styles.maxWidth.sm};
  }
`;

const FlowerTypeIndicatorContainer = styled.div.attrs(() => ({
  className: "product-card-horizontal__flower-type-indicator-container",
  "data-keep-cart": "true",
}))``;

const Content = styled.div.attrs(() => ({
  className: "product-card-horizontal__content",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 155px - 8px);
`;

const ProductInfo = styled.div.attrs(() => ({
  className: "product-card-horizontal__info",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: column;

  > a {
    text-decoration: none;
  }

  ${media.up("lg")} {
    padding: ${({styles}) => styles.padding.lg};

    > * {
      margin-bottom: ${({styles}) => styles.childrenMarginBottom.lg};
    }
  }

  ${media.down("md")} {
    padding: ${({styles}) => styles.padding.md};

    > * {
      margin-bottom: ${({styles}) => styles.childrenMarginBottom.md};
    }
  }

  ${media.down("sm")} {
    padding: ${({styles}) => styles.padding.sm};

    > * {
      margin-bottom: ${({styles}) => styles.childrenMarginBottom.sm};
    }
  }
`;

const BrandName = styled.span.attrs(() => ({
  className: "product-card-horizontal__brand-name",
  "data-keep-cart": "true",
}))`
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-transform: ${({styles}) => styles.textTransform};
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }

  &:hover {
    text-decoration: underline;
  }
`;

const Name = styled.span.attrs(() => ({
  className: "product-card-horizontal__name",
  "data-keep-cart": "true",
}))`
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  line-height: ${({styles}) => styles.lineHeight};
  color: ${({styles}) => styles.color};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }

  &:hover {
    text-decoration: underline;
  }
`;

const ProductSize = styled.span.attrs(() => ({
  className: "product-card-horizontal__product-size",
  "data-keep-cart": "true",
}))`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  font-family: ${({styles}) => styles.fontFamily};
  color: ${({styles}) => styles.color};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const ProductActions = styled.div.attrs(() => ({
  className: "product-card-horizontal__actions",
  "data-keep-cart": "true",
}))`
  ${media.up("lg")} {
    padding: ${({styles}) => styles.padding.lg};
  }

  ${media.down("md")} {
    padding: ${({styles}) => styles.padding.md};
  }

  ${media.down("sm")} {
    padding: ${({styles}) => styles.padding.sm};
  }
`;

const defaultStyles = {
  root: {
    fontFamily: "sans-serif",
    columnGap: "8px",
    borderRadius: "10px",
    boxShadow: "rgb(0 0 0 / 20%) 0px 0px 10px -3px !important",
    border: "none",
    hoverBorder: "none",
    height: {
      lg: "175px",
      md: "175px",
      sm: "175px",
    },
    maxWidth: {
      lg: "384px",
      md: "384px",
      sm: "384px",
    },
    minWidth: {
      lg: "360px",
      md: "360px",
      sm: "360px",
    },
    padding: {
      lg: "0",
      md: "0",
      sm: "0",
    },
  },
  image: {
    padding: {
      lg: "0",
      md: "0",
      sm: "0",
    },
    objectFit: "contain",
    borderRadius: "0",
    height: {
      lg: "175px",
      md: "175px",
      sm: "175px",
    },
    minWidth: {
      lg: "",
      md: "",
      sm: "",
    },
    maxWidth: {
      lg: "",
      md: "",
      sm: "",
    },
  },
  flowerTypeIndicator: {
    position: {
      top: spacing(1),
      left: spacing(1),
    },
    fontSize: {
      lg: "8px",
      md: "6px",
      sm: "6px",
    },
  },
  saleIndicator: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "12px",
      md: "10px",
      sm: "8px",
    },
    backgroundColor: "red",
    borderRadius: "0",
    top: "0px",
  },
  info: {
    padding: {
      lg: 0,
      md: 0,
      sm: 0,
    },
    childrenMarginBottom: {
      lg: spacing(1),
      md: spacing(1),
      sm: spacing(1),
    },
  },
  brandName: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "10px",
      md: "10px",
      sm: "10px",
    },
    fontWeight: "600",
    color: "#333",
    textTransform: "none",
  },
  productName: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: "400",
    color: "#333",
    lineHeight: "1.4",
  },
  productSizes: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "12px",
      md: "12px",
      sm: "12px",
    },
    fontWeight: "400",
    color: "#777",
  },
  potencyTag: {
    columnGap: "4px",
    rowGap: "4px",
    padding: {
      lg: "4px",
      md: "4px",
      sm: "4px",
    },
  },
  actionsContainer: {
    padding: {
      lg: spacing(2),
      md: spacing(1),
      sm: spacing(1),
    },
  },
  actions: {},
};

HorizontalProductCard.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      fontFamily: PropTypes.string,
      borderRadius: PropTypes.string,
      boxShadow: PropTypes.string,
      border: PropTypes.string,
      hoverBorder: PropTypes.string,
      height: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      minWidth: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      maxWidth: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    image: PropTypes.shape({
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      objectFit: PropTypes.string,
      borderRadius: PropTypes.string,
      height: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    flowerTypeIndicator: PropTypes.shape({
      top: PropTypes.string,
      bottom: PropTypes.string,
      left: PropTypes.string,
      right: PropTypes.string,
    }),
    salesIndicator: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.string,
      backgroundColor: PropTypes.string,
    }),
    info: PropTypes.shape({
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    productName: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
      lineHeight: PropTypes.string,
    }),
    brandName: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
      textTransform: PropTypes.string,
    }),
    productSizes: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
    }),
    potencyTag: PropTypes.shape({
      columnGap: PropTypes.string,
      rowGap: PropTypes.string,
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    actionsContainer: PropTypes.shape({
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    actions: PropTypes.object,
  }),
  skeleton: PropTypes.bool,
  loading: PropTypes.bool,
  zoomOnHover: PropTypes.bool,
  productDetailUrl: PropTypes.string,
  imageUrl: PropTypes.string,
  onSale: PropTypes.bool,
  salesIndicatorPosition: PropTypes.string,
  flowerType: PropTypes.shape({
    icon: PropTypes.elementType,
    color: PropTypes.string,
    name: PropTypes.string,
    fontSize: PropTypes.shape({
      lg: PropTypes.string,
      md: PropTypes.string,
      sm: PropTypes.string,
    }),
  }),
  hideFlowerIndicatorNameOnSmallerScreens: PropTypes.bool,
  flowerTypeIndicatorProps: PropTypes.shape({
    getBackground: PropTypes.func,
    getColor: PropTypes.func,
    getIconColors: PropTypes.func,
  }),
  brandName: PropTypes.string,
  brandUrl: PropTypes.string,
  name: PropTypes.string,
  sizes: PropTypes.arrayOf(PropTypes.string),
  showSizes: PropTypes.bool,
  potencyTags: PropTypes.arrayOf(PropTypes.string),
  potencyTagsPosition: PropTypes.bool,
  LinkComponent: PropTypes.elementType,
  FlowerTypeIndicatorComponent: PropTypes.elementType,
  SaleIndicatorComponent: PropTypes.elementType,
  PotencyTagComponent: PropTypes.elementType,
  ActionsComponent: PropTypes.elementType,
};

HorizontalProductCard.defaultProps = {
  styles: defaultStyles,
  LinkComponent: ({to, children}) => <a href={to}>{children}</a>,
  FlowerTypeIndicatorComponent: SimpleFlowerTypeIndicator,
  SaleIndicatorComponent: SaleIndicator,
  PotencyTagComponent: PotencyTag,
  ActionsComponent: () => <div />,
};

export default HorizontalProductCard;
