import React from "react";
import PropTypes from "prop-types";
import {validateUrl} from "@ui/utils/validateUrl";
import styled from "styled-components";
import media from "@ui/utils/media";
import ConfigurableImage from "@ui/components/ConfigurableImage";
import merge from "lodash/merge";
import spacing from "@ui/utils/spacing";
import Card from "@ui/components/Card/Card";
import SimpleFlowerTypeIndicator from "../FlowerTypeIndicator/SimpleFlowerTypeIndicator";
import {PlusIcon} from "@ui/components/Icons";
import {renderNodeOrComponent} from "@ui/components";

function ImageCard({
  styles,
  skeleton,
  SkeletonIconComponent,
  zoomOnHover,
  loading,
  imageUrl,
  productDetailUrl,
  hideFlowerIndicatorNameOnSmallerScreens,
  showFlowerTypeIcon,
  flowerTypeIndicatorProps = {},
  FlowerTypeIndicatorComponent,
  flowerType,
  flowerTypePosition,
  LinkComponent,
}) {
  const _styles = merge({}, defaultStyles, styles);

  if (skeleton) {
    return (
      <Card
        styles={_styles.root}
        $loading={loading}
        zoomOnHover={false}
        showHeader={false}
      >
        <IconContainer>{renderNodeOrComponent(SkeletonIconComponent)}</IconContainer>
      </Card>
    );
  }

  const flowerTypeSection = (
    <FlowerTypeIndicatorContainer
      flowerTypePosition={flowerTypePosition}
      styles={_styles.flowerTypeIndicator}
    >
      <FlowerTypeIndicatorComponent
        {...flowerTypeIndicatorProps}
        showIcon={showFlowerTypeIcon}
        flowerTypeIndicatorProps={flowerTypeIndicatorProps}
        hideNameOnSmallerScreens={hideFlowerIndicatorNameOnSmallerScreens}
        flowerType={flowerType}
        styles={_styles.flowerTypeIndicator}
      />
    </FlowerTypeIndicatorContainer>
  );

  return (
    <Card
      styles={_styles.root}
      $loading={loading}
      zoomOnHover={zoomOnHover}
      showHeader={false}
    >
      <ProductImageContainer styles={_styles.image}>
        <LinkComponent to={productDetailUrl}>
          {validateUrl(imageUrl) && (
            <ProductImage
              styles={_styles.image}
              source={imageUrl}
              height={_styles.image.height.lg.replace("px", "")}
            />
          )}
          {flowerType &&
            flowerTypePosition === FlowerTypePositions.IMAGE &&
            flowerTypeSection}
        </LinkComponent>
      </ProductImageContainer>
    </Card>
  );
}

const defaultStyles = {
  root: {
    fontFamily: "sans-serif",
    borderRadius: "10px",
    boxShadow: "rgb(0 0 0 / 20%) 0px 0px 10px -3px !important",
    onHoverBoxShadow: "",
    border: "none",
    hoverBorder: "none",
    height: {
      lg: "175px",
      md: "175px",
      sm: "175px",
    },
    minWidth: {
      lg: "150px",
      md: "150px",
      sm: "130px",
    },
    maxWidth: {
      lg: "250px",
      md: "250px",
      sm: "250px",
    },
  },
  image: {
    objectFit: "contain",
    height: {
      lg: "175px",
      md: "175px",
      sm: "175px",
    },
    maxWidth: {
      lg: "",
      md: "",
      sm: "",
    },
    padding: {
      lg: "0",
      md: "0",
      sm: "0",
    },
    borderRadius: "0",
  },
  flowerTypeIndicator: {
    position: {
      top: spacing(1),
      left: spacing(1),
    },
  },
  saleIndicator: {
    fontSize: {
      lg: "8px",
      md: "8px",
      sm: "8px",
    },
    backgroundColor: "red",
    fontFamily: "sans-serif",
    margin: {
      lg: "",
      md: "",
      sm: "",
    },
  },
};

const ProductImageContainer = styled.div.attrs(() => ({
  className: "product-card__image-container",
  "data-keep-cart": "true",
}))`
  ${media.up("lg")} {
    padding: ${({styles}) => styles.padding.lg};
  }

  ${media.down("md")} {
    padding: ${({styles}) => styles.padding.md};
  }

  ${media.down("sm")} {
    padding: ${({styles}) => styles.padding.sm};
  }

  > a {
    display: flex;
    position: relative;
  }

  height: auto;
`;

const ProductImage = styled(ConfigurableImage).attrs(() => ({
  className: "product-card__image",
  "data-keep-cart": "true",
}))`
  width: 100%;
  object-fit: ${({styles}) => styles.objectFit};

  ${media.up("lg")} {
    height: ${({styles}) => styles.height.lg};
    max-width: ${({styles}) => styles.maxWidth.lg};
  }

  ${media.down("md")} {
    height: ${({styles}) => styles.height.md};
    max-width: ${({styles}) => styles.maxWidth.md};
  }

  ${media.down("sm")} {
    height: ${({styles}) => styles.height.sm};
    max-width: ${({styles}) => styles.maxWidth.sm};
  }

  border-radius: ${({styles}) => styles.borderRadius};
`;

const IconContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FlowerTypeIndicatorContainer = styled.div.attrs(() => ({
  className: "product-card__flower-type-indicator-container",
  "data-keep-cart": "true",
}))`
  position: ${({flowerTypePosition}) =>
    flowerTypePosition === FlowerTypePositions.IMAGE ? "absolute" : "relative"};

  margin: ${({styles}) => styles?.margin};

  ${({styles, flowerTypePosition}) => getFlowerTypePosition(styles, flowerTypePosition)};
`;

const FlowerTypePositions = {
  IMAGE: "image",
  BRAND: "brandName",
  CARD_TOP_LEFT: "card-top-left",
  CUSTOM: "custom",
};
const getFlowerTypePosition = (styles, flowerTypePosition) => {
  if (flowerTypePosition === FlowerTypePositions.IMAGE) {
    return `
      position: absolute;
      top: ${styles.position.top};
      right: ${styles.position.right};
      bottom: ${styles.position.bottom};
      left: ${styles.position.left};
    `;
  } else if (flowerTypePosition === FlowerTypePositions.CARD_TOP_LEFT) {
    return `
      position: absolute;
      top: 20px;
      left: -20px;
      z-index: 4;
    `;
  } else if (flowerTypePosition === FlowerTypePositions.CUSTOM) {
    return `
      position: absolute;
      top: ${styles.position.top};
      left: ${styles.position.left};
      z-index: 4;
    `;
  } else {
    return "";
  }
};

ImageCard.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      fontFamily: PropTypes.string,
      borderRadius: PropTypes.string,
      boxShadow: PropTypes.string,
      onHoverBoxShadow: PropTypes.string,
      border: PropTypes.string,
      hoverBorder: PropTypes.string,
      height: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      minWidth: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      maxWidth: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    image: PropTypes.shape({
      objectFit: PropTypes.string,
      height: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      borderRadius: PropTypes.string,
      borderBottom: PropTypes.string,
    }),
    flowerTypeIndicator: PropTypes.object,
    saleIndicator: PropTypes.object,
  }),
  zoomOnHover: PropTypes.bool,
  loading: PropTypes.bool,
  imageUrl: PropTypes.string,
  productDetailUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  flowerType: PropTypes.shape({
    icon: PropTypes.elementType,
    color: PropTypes.string,
    name: PropTypes.string,
  }),
  hideFlowerIndicatorNameOnSmallerScreens: PropTypes.bool,
  showFlowerTypeIcon: PropTypes.bool,
  simpleFlowerIndicator: PropTypes.bool,
  FlowerTypeIndicatorComponent: PropTypes.elementType,
  LinkComponent: PropTypes.elementType,
  skeleton: PropTypes.bool,
  SkeletonIconComponent: PropTypes.elementType,
  flowerTypeIndicatorProps: PropTypes.object,
  flowerTypePosition: PropTypes.oneOf(Object.values(FlowerTypePositions)),
};
ImageCard.defaultProps = {
  styles: defaultStyles,
  hideFlowerIndicatorNameOnSmallerScreens: false,
  showFlowerTypeIcon: true,
  zoomOnHover: true,
  LinkComponent: ({to, children}) => <a href={to}>{children}</a>,
  FlowerTypeIndicatorComponent: SimpleFlowerTypeIndicator,
  skeleton: false,
  SkeletonIconComponent: <PlusIcon size={53} color="#eeeeee" />,
  flowerTypePosition: FlowerTypePositions.IMAGE,
};

export default ImageCard;
